export const checkBoundationArray = (event, girdItemsSelected, docDiv, draggingIndex, clientXX, clientYY) =>{
    let tempObj = {};
    let arrayTemp = JSON.parse(JSON.stringify(girdItemsSelected));
    let flagMatched = false;
    const isSafari = navigator.userAgent.includes("Mac");
    girdItemsSelected.forEach((element, index) => {
        const docDiv = document.getElementById(element.id+element.filename+index);
        const posOfDiv = docDiv.getBoundingClientRect(); 
        const startXOfDiv = posOfDiv.x+15;
        const startYOfDiv = posOfDiv.y+15;
        const endXOfDiv = posOfDiv.x + posOfDiv.width-15;
        const endYOfDiv = posOfDiv.y + posOfDiv.height+15;
        const filenameOfDoc = docDiv.getAttribute('data-elem-filename');
        const imageOfDoc = docDiv.getAttribute('data-elem-image');
        const indexOfDoc = docDiv.getAttribute('data-elem-index');
        const clientXValue = isSafari ? clientXX: event.clientX;
        const clientYValue = isSafari ? clientYY: event.clientY;
        if(!flagMatched && (clientXValue > startXOfDiv) && (clientXValue < endXOfDiv) && (clientYValue > startYOfDiv) && (clientYValue < endYOfDiv)){
            flagMatched = true;
            if(filenameOfDoc != "Not found" && imageOfDoc != "Not found"){
                tempObj = girdItemsSelected[draggingIndex];
                arrayTemp[draggingIndex] = {id: arrayTemp[draggingIndex].id, image: element.image, filename: element.filename, checked: element.checked};
                arrayTemp[indexOfDoc] = {id: arrayTemp[indexOfDoc].id, image: tempObj.image, filename: tempObj.filename, checked: tempObj.checked};
            }
        }
    });
    return arrayTemp;
}