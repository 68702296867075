import React, { useEffect, useState } from "react";
import EndPoint from "../../api/Endpoint";
import { redirect, useParams,useLocation  } from "react-router-dom";
import UserNavbar from "../../components/NavBar/userNav.jsx";
import { useHttpClient } from "../../../src/hooks/HttpHook";
import _ from 'lodash'
import NoPDFModal from './no-pdf-modal';
import Loader from "../Loading/Loader";

const CategoryPage = (props) => {
    const location = useLocation()
    const categoryPdf = location.state.item;
    const [pdfcategories, setPDFCategories] = useState([]);
    const [curretPdfUrl, setCurrentPDF] = useState('');
    const [curretPdfUrlIndex, setCurrentPDFIndex] = useState(0);
    const [sortedPDF, setSortedPdf] = useState([]);
    const [nextBtn, setNextBtn] = useState(false);
    const [prevBtn, setPrevBtn] = useState(false); 
    const { sendRequest } = useHttpClient();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (localStorage.getItem('token') == null) {
            redirect(`${EndPoint.FRONT_LOGIN_URL}`);
        }
        sortTheUpcomingPDF();
    }, []);

    const sortTheUpcomingPDF = () =>{
        const pdfArr = categoryPdf['pdfs'];
        if(!pdfArr || pdfArr?.length === 0){
            setNextBtn(true);
            setPrevBtn(true);
            return;
        }
        let indexVal = 0;
        if(pdfArr.length > 1){
            let valArr = pdfArr;
            valArr = _.orderBy(valArr, ['orderId'],['asc']); // Use Lodash to sort array by 'name'
            valArr = valArr.filter(obj => Object.keys(obj).includes("orderId")); 
            valArr.forEach((element,index) => {
                if(element.orderId >=0 && indexVal === 0){
                    console.log("############ valArr ###############", element, index);
                    indexVal = index;                    
                }
            });
            if(indexVal === 0){
                let max = -Infinity;
                let keyIndex = 0;  
                valArr.forEach((v, k) => { 
                    if (v['orderId'] && max < +v['orderId']) { 
                        max = +v['orderId']; 
                        keyIndex = k; 
                    }
                });
                indexVal = keyIndex;
            } 
            setCurrentPDFIndex(indexVal);
            setSortedPdf(valArr);
            fetchURLOfPDF(valArr[indexVal], indexVal);
        }else{
            let valArr = pdfArr;
            // valArr = _.orderBy(valArr, ['orderId'],['asc']); // Use Lodash to sort array by 'name'
            valArr = valArr.filter(obj => Object.keys(obj).includes("orderId"));
            if(valArr.length > 0){
                setCurrentPDFIndex(0);
                setSortedPdf(valArr);
                fetchURLOfPDF(valArr[0], 0);
            }else{
                setNextBtn(true);
                setPrevBtn(true);
            }
        }
    }
    
    const fetchURLOfPDF = async (sortedPDF, indexVal) => {
        try {
            setLoading(true);
            let pdfURL = sortedPDF.pdfUrl;
            disableNextPrevButtons(indexVal);
            const responseData = await sendRequest(
                EndPoint.BASE_URL_CATEGORY_FRONT_API + pdfURL,
                "GET",
            );
            setLoading(true);
            console.log("fetchURLOfPDF", responseData)
            setCurrentPDF(responseData.data);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const disableNextPrevButtons = (indexVal) => {
        const pdfArr = categoryPdf['pdfs'];
        const valArr = pdfArr.filter(obj => Object.keys(obj).includes("orderId"));
        const pdfLength = valArr.length -1;
        if(pdfLength === 0){
            setNextBtn(true);
            setPrevBtn(true);
        }else if(indexVal === 0){
            setPrevBtn(true);
            setNextBtn(false);
        }else if(pdfLength === indexVal){
            setNextBtn(true);
            setPrevBtn(false);
        }else{
            setNextBtn(false);
            setPrevBtn(false);
        }
    }

    const changePageBack = () => {
        if(prevBtn){
            setShow(true);
            return;
        }
        const prevIndex = curretPdfUrlIndex -1;
        setCurrentPDFIndex(prevIndex);
        fetchURLOfPDF(sortedPDF[prevIndex], prevIndex);
    }
    const changePagNext = () =>  {       
        if(nextBtn){
            setShow(true);
            return;
        }
        const nextIndex = curretPdfUrlIndex +1;
        setCurrentPDFIndex(nextIndex);
        fetchURLOfPDF(sortedPDF[nextIndex], nextIndex);
    }

    const handleOnLoad = () => {
        console.log("##### handleOnLoad #####")
        setLoading(false);
    }

    return (
        <>
            <UserNavbar />
            <section>
                <div className="container">
                    <div className="row">
                        <div className="pdf-main-outer d-flex justify-content-between w-100">
                            <div className="left-side-btn">
                                {/* {pageNumber > 1 && */}
                                <button className={prevBtn ? 'pre-btn disable':'pre-btn'} onClick={changePageBack} >
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height={11} width={6} fill='#fff' viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" /></svg>
                                    </span>
                                    Previous
                                </button>
                                {/* // } */}
                                {/* sdfs */}
                            </div>
                            <div className="center-title">{categoryPdf.title}</div>
                            <div className="right-side-button">
                                {/* { pageNumber < numPages && */}
                                <button className={nextBtn?'next-btn disable':'next-btn'} onClick={changePagNext}>NEXT
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" height={11} width={6} fill='#fff' viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" /></svg>
                                    </span>
                                </button>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="page-inner-cover">
                <div className="container">
                    {
                        sortedPDF.length == 0 ?
                        <div className="pdf-btn-outer">
                            No pdfs in selected category.
                        </div>
                        :
                        <div className="pdf-btn-outer">
                            {/* <iframe src="/pdf-sample.pdf#toolbar=0" height="735px" width="100%" controls={false} ></iframe > */}
                            <iframe src={curretPdfUrl} height="735px" width="100%" onLoad={handleOnLoad} controls={false} ></iframe >
                        </div>
                    }


                </div>
                {loading?(
                    <Loader />
                ):null}
            </section>
            <NoPDFModal showModal={show} handleClose={handleClose} data='' />
        </>
    );
};
export default CategoryPage;