import React, { useState, useEffect } from "react";
import axios from "axios";
import Category from "./Category";
import EndPoint from "../../api/Endpoint";
import CategoryAdd from "../CategoryAdd";
import CategoryModal from "../CategoryAdd/modal";
import PDFCategoryModal from "../CategoryAdd/pdfModal";
import { useHttpClient } from "../../hooks/HttpHook";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Button } from "react-bootstrap";
import Navbar from "../../components/NavBar";
import FileUploadModal from "./fileUploadModal";
const CategoryList = () => {
  const [categories, setCategories] = useState([]);

  const [categoryId, seCategoryId] = useState(0);
  const [show, setShow] = useState(false);
  const { sendRequest } = useHttpClient();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({});

  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const [pdf_show, setPdfShow] = useState(false);
  const handlePdfClose = () => setPdfShow(false);

  const [category, setCategory] = useState({});
  const [pdf_category, setPdfCategory] = useState({});
  const [file_modal_show, setFileModalShow] = useState(false);


  const handleClose = () => {
    setShow(false);
    setTimeout(() => {
      // setCategory({});
      setIsLoading(false);
      setResponce({
        show: false,
        message: "",
        type: "error",
      });
    }, 5000);
  };

  const handleCloseRequest = () => {
    fetchData();    
    setTimeout(() => {
      
      setIsLoading(false);
      setShow(false);
      setResponce({
        show: false,
        message: "",
        type: "error",
      });

    }, 1000);
  };

  const editFormCategory = (data) => {
    setIsLoading(false);
    setCategory(data);
    setShow(true);
  };

  const addFormCategory = () => {
    setIsLoading(false);
    setCategory({'title':''});
    setShow(true);
  };



  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const responseData = await sendRequest(
        EndPoint.CATEGORY_URL_All + "?status=all"
      );
      if(responseData.data.length > 0){
        setCategories(responseData.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStatusChange = async (categoryData) => {
    categoryData.status =
      categoryData.status == "active" ? "inactive" : "active";
    try {
      const responseData = await sendRequest(
        EndPoint.UPDATE_CATEGORY_URL + "/" + categoryData.id,
        "PATCH",
        categoryData
      );
    } catch (err) {
      console.log(err);
    }
  };


  const onCategorySubmit = (formDataArr) => {
    setCategory(formDataArr);

    setIsLoading(true);
    var token = localStorage.getItem("token");
    delete formDataArr["orderId"];
    if (formDataArr.id != undefined) {
      // console.log('update',formDataArr);
      axios
        .patch(
          EndPoint.UPDATE_CATEGORY_URL + "/" + formDataArr.id,
          formDataArr,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(
          (response) => {
          
            setResponce({
              show: true,
              message: "Merch Grid update Successfully",
              type: "success",
            });
           
           handleCloseRequest();
           
          },
          (error) => {
            if(error.response.status == 403){
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              window.location.href= `${EndPoint.FRONT_LOGIN_URL}`;;
           }
             setIsLoading(false);
            setResponce({
              show: true,
              message: error.response.data.message,
              type: "error",
            });
          }
        )
        .catch((e) => console.log(e));
    } else {
      axios
        .post(EndPoint.CREATE_CATEGORY_URL, formDataArr, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(
          (response) => {
            setResponce({
              show: true,
              message: "Merch Grid added Successfully",
              type: "success",
            });
       
            handleCloseRequest();
          },
          (error) => {
            if(error.response.status == 403){
              localStorage.removeItem('token');
              localStorage.removeItem('user');
              window.location.href= `${EndPoint.FRONT_LOGIN_URL}`;;
           }
            setIsLoading(false);
            setResponce({
              show: true,
              message: error.response.data.message,
              type: "error",
            });
          }
        )
        .catch((e) => console.log(e));
    }
  };

  const handleDragEnd = async (e) => {
    //  if (!e.destination) return;

    let tempData = Array.from(categories);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setCategories(tempData);
    const data = tempData.map((item)=>{
           return item._id
    });
    try {
      const responseData = await sendRequest(
        EndPoint.UPDATE_CATEGORY_ORDER_URL + "/" + source_data.id,
        "PATCH",
        {
         data:data,
        }
      );
    } catch (err) {
      console.log(err);
    }
  

   
  };

  const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",

  // change background colour if dragging

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  padding: grid,
});

const editPDFFormCategory = (data) => {
  seCategoryId(data.id);
  setResponce({})
  setPdfCategory({});
  setPdfShow(true);
};

const onCategoryPDFSubmit = (formDataArr) => {
  setIsLoading(true);
  console.log(formDataArr);
  var token =  localStorage.getItem('token');
  var formData = new FormData();
  var imagefile = document.querySelector('#pdf');
  if(formDataArr.id !== undefined){
    formData.append("id",formDataArr.id);

  }
  if(formDataArr.removePDFFile !== undefined){
    formData.append("removePDFFile",formDataArr.removePDFFile);

  }
  formData.append("pdf", imagefile.files[0]);
  formData.append("startDate", formDataArr.startDate);
  formData.append("endDate", formDataArr.endDate);
  var myHeaders = new Headers();
  myHeaders.append("Authorization",`Bearer ${token}`);
  

  axios
    .patch(EndPoint.CHECK_UPDATE_CATEGORY_PDF_URL+categoryId, formDataArr,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      }
      )
    .then(
      (response) => {
       
        axios
        .patch(EndPoint.UPDATE_CATEGORY_PDF_URL+categoryId, formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          }
          )
        .then(
          (response) => {
            setIsLoading(false);
            setResponce({
              show: true,
              message: "Merch Grid update Successfully",
              type: "success",
            });
  
            fetchData();
            setPdfCategory({});
            setPdfShow(false);
          
          },
          (error) => {
            setIsLoading(false);
            setResponce({
              show: true,
              message: error.response.data.message,
              type: "error",
            });
          }
        )
        .catch((e) => console.log(e));
      
      },
      (error) => {
        setIsLoading(false);
        setResponce({
          show: true,
          message: error.data.message,
          type: "error",
        });
      }
    )
    .catch((e) => console.log(e));
};

const handleFileModalOpen = () =>{
  setFileModalShow(true);
}

const handleFileModalClose = () =>{
  setFileModalShow(false);
}




  return (
    <>  
      <Navbar/>
      <div className="container">
        <div className="row category-top upload-btn-cover">
          <span className="col-lg-6"></span>
          <span className="col-lg-6 text-right d-flex align-items-center justify-content-end">
            <Button className="upload-btn" variant="primary" onMouseDown={handleFileModalOpen}>
              <span className="mr-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.484" height="13.484" viewBox="0 0 13.484 13.484">
                  <g id="Icon_feather-upload" data-name="Icon feather-upload" transform="translate(-3.5 -3.5)">
                    <path id="Path_46" data-name="Path 46" d="M15.984,22.5v2.552a1.276,1.276,0,0,1-1.276,1.276H5.776A1.276,1.276,0,0,1,4.5,25.052V22.5" transform="translate(0 -10.344)" fill="none" stroke="#f36c21" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_47" data-name="Path 47" d="M16.88,7.69,13.69,4.5,10.5,7.69" transform="translate(-3.448)" fill="none" stroke="#f36c21" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_48" data-name="Path 48" d="M18,4.5v7.656" transform="translate(-7.758)" fill="none" stroke="#f36c21" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  </g>
                </svg>
              </span>
              Upload Sheet
            </Button>
            <Button variant="primary" onClick={addFormCategory}>
              CREATE New Merch Grid
            </Button>
          </span>
        </div>

        <div className="row category-bottom">
          <div className="col-md-12">
            <div className="table-responsive">
              {categories.length > 0 ? (
                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="tezst">
                                    {(provided, snapshot) => (
                  <table
                  style={getListStyle(snapshot.isDraggingOver)}
                  id="tezst" className="table table-striped">
                    <thead>
                      <tr>
                        <th className="col-sm category-title" scope="col">
                        Merch Grids
                        </th>
                        <th className="col-sm" scope="col"></th>
                        <th className="col-sm" scope="col"></th>
                        <th className="col-sm" scope="col"></th>
                      </tr>
                    </thead>

                  
                        <tbody
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {categories?.map((item, index) => (
                            <Draggable
                              key={item._id}
                              draggableId={item._id.toString()}
                              index={index}
                            >
                              {(provider,snapshot) => (
                                <Category
                                  provider={provider}
                                  snapshot={snapshot}
                                  getItemStyle={getItemStyle}
                                  index={index}
                                  data={item}
                                  handleOpen={editFormCategory}
                                  handleStatusChange={handleStatusChange}
                                  handlePdfOpen={editPDFFormCategory}
                                />
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      
                    
                  </table>
                  )}
                  </Droppable>
                </DragDropContext>
              ) : null}
            </div>
          </div>
        </div>
      {show ?(
        <CategoryModal
          responseObj={responseObj}
          showModal={show}
          handleClose={handleClose}
          onSubmit={onCategorySubmit}
          data={category}
          isLoading={isLoading}
        />
        ):''}
          <PDFCategoryModal
            responseObj={responseObj}
            showModal={pdf_show}
            handleClose={handlePdfClose}
            data={pdf_category}
            isLoading={isLoading}
            isEdit={true}
            onCategoryPDFSubmit={onCategoryPDFSubmit}
          />        
        <FileUploadModal
          showModal={file_modal_show}
          handleClose={handleFileModalClose}
        />
        {isLoading?(
          <div className="loaderCoder">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          </div>
        ):null}
      </div>
    </>
  );
};
export default CategoryList;
