const SearchBar = ({keyword, onChange, setShowDropDown}) => {
    const BarStyle = {width:"100%"};
    return (
      <input 
       style={BarStyle}
       key="search-bar"
       value={keyword}
       placeholder={"Enter Address for Packout Tracking"}
       onChange={(e) => onChange(e.target.value)}
       className="input-search mr-0"
       onBlur={()=>setShowDropDown(false)}
       onFocus={()=>setShowDropDown(true)}
      />
    );
  }
  
  export default SearchBar;