import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import EndPoint from "../../api/Endpoint";
import { useHttpClient } from "../../hooks/HttpHook";
import Dropdown from 'react-bootstrap/Dropdown';
import headerLogoNew from "../../assets/img/header-logo-new.png";
import ImageTag from "../ImageTag";
import { slice } from 'lodash'
import Loading from "../Loading";
import Constants from "../../api/Constants";
import UserNavbar from "../NavBar/userNav";
const UserCategoryList = (props) => {
    const [categories, setCategories] = useState([]);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [isCompleted, setIsCompleted] = useState(false)
    const [index, setIndex] = useState(10)
    const [initialPosts, setInitialPosts]= useState([]);
    const [responseObj, setResponce] = useState({
        show: false,
        message: "",
        type: "error",
    });
    const loadMore = () => {
        const dataArr = slice(categories, 0, (index+5))
        setInitialPosts(dataArr);
        setIndex(index + 5)
    }

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const responseData = await sendRequest(
                EndPoint.CATEGORY_URL
            );
            const initialDataArr = responseData.data; //slice(, 0, 10);
            setInitialPosts(initialDataArr);
            setCategories(responseData.data);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <UserNavbar />
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-categories">Categories</div>
                            <div className="pdf-inner-outer">
                                {initialPosts?.map((item, index) => (
                                    <Link to={`${Constants.FRONT_BASE_PATH}/user/category-pdf`} state={{item }} >
                                        <div className="pdf-repeat-box d-flex justify-content-between">{item.title}
                                            {/* {console.log("item",item)} */}
                                            <span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="8.083" height="14.148" viewBox="0 0 8.083 14.148">
                                                    <path id="chevron-down-solid" d="M38.285,167.712a1.012,1.012,0,0,0,1.43,0l6.061-6.061a1.011,1.011,0,1,0-1.43-1.43L39,165.569l-5.348-5.345a1.011,1.011,0,0,0-1.43,1.43l6.061,6.061Z" transform="translate(-159.925 46.073) rotate(-90)" />
                                                </svg>
                                            </span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                            {/* <div className="text-center load-btn">
                                <button onClick={loadMore} type="button" className="btn ">
                                    Load More...
                                </button>
                            </div> */}  
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default UserCategoryList;
