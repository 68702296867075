import cutright from '../../assets/img/cutright.png';
import cutup from '../../assets/img/cutup.png';
import ImageTag from '../ImageTag';
import Modal from 'react-bootstrap/Modal';
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";
// Default export is a4 paper, portrait, using millimeters for units

export default function PreviewModal(props){
    useEffect(()=>{
        const priceModalScrollId = document.getElementById("price-modal-scroll-id");
    },[props.girdItemsSelected, props.show]); 

    const [gridItemArr, setGridItemArr] = useState([]);
    
    useEffect(()=>{
        let gridItemsSelectedArr = JSON.parse(JSON.stringify(props.girdItemsSelected));
        let lengthOfArr = gridItemsSelectedArr.length;
        if(lengthOfArr >6){
            breakGridItemsSelected();
        }else{
            const gridItemArrTemp=[];
            gridItemArrTemp[0]=gridItemsSelectedArr;
            setGridItemArr(gridItemArrTemp);
        }
    },[props.girdItemsSelected, props.show]); 

    const breakGridItemsSelected = () =>{
        let gridItemsSelectedArr = JSON.parse(JSON.stringify(props.girdItemsSelected));
        let chunk = [];
        let tempGridItemArr = [];
        while (gridItemsSelectedArr.length > 0) {
            chunk = gridItemsSelectedArr.splice(0,6);
            if(chunk.length < 6){
                let lengthOfChunk = chunk.length;
                let numOfIterations = 6 - lengthOfChunk;
                for(let i=0;i<numOfIterations;i++){
                    chunk.push({filename:"", image:"", checked:false})
                }
            }
            tempGridItemArr.push(chunk);
        }
        setGridItemArr(tempGridItemArr);
    }


    return(
        <Modal className='price-tag-modal preview-pdf-modal' show={props.show} onHide={props.handleClose}>  
        <div className={"price-modal-scroll"}>
            <Modal.Body>
                    {
                        gridItemArr.map((element,index)=>{
                            return(
                                <div className={"row"}>
                                    <div className="col-3 position-relative">
                                    <div className="left-title-of-pdf">Cut each price tag at the dotted line(s).</div>
                                    <div className='modal-left-icon'>
                                        <ImageTag src={cutright} /> 
                                    </div>
                                    <div className="left-title-of-pdf">Corte cada etiqueta de precio por las línea(s) de puntos.</div>
                                    <div className='modal-left-icon modal-left-icon2'>
                                        <ImageTag src={cutright} /> 
                                    </div>
                                    <div className="left-title-of-pdf"></div>
                                    <div className='modal-left-icon modal-left-icon3'>
                                        <ImageTag src={cutright } /> 
                                    </div>
                                    </div>
                                    <div className="col-9 ">
                                    <div className="row">
                                    {element.map((elemData, index) => {
                                        return(
                                        <div className="col-6 price-preview-border p-0">
                                            <div className="price-preview-right-side-cover">
                                            {elemData.image != '' &&
                                            <>                      
                                                <ImageTag src={elemData.image}/> 
                                            </>
                                            }
                                            </div>
                                        </div>
                                        )
                                    })}
                                    </div>
                                    </div>
                                    <div className='modal-bottom-icon '>
                                    <ImageTag src={cutup} /> 
                                    </div>
                                    <div className='modal-bottom-icon modal-left-icon3'>
                                    <ImageTag src={cutup} /> 
                                    </div>
                                </div>
                            )
                            })                   
                    }
            </Modal.Body>
        </div>            
            
        </Modal>
    ); 
}