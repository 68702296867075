import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import pdfIcon from "../../assets/img/file-plus-regular.svg";
import CloseIcon from "../../assets/img/modal-close-icon.svg";
import EndPoint from "../../api/Endpoint";
import { useHttpClient } from "../../hooks/HttpHook";
import axios from "axios";
const FileUploadModal = (prop) => {
  const [locationFile, setLocationFile] = useState({});
  const {error, sendRequest, clearError } = useHttpClient();
  const  [isLoading, setIsLoading] = useState(false);
  const handleChange = (event) => {
    console.log("######## handleChange #############", event.target.files[0]);
    const fileFetched = event.target.files[0];
    if(fileFetched.type === 'application/vnd.ms-excel' || fileFetched.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
      setLocationFile(event.target.files[0]);
    }else{
      alert("please upload file in xlsx/xls format.");
    }
  };

  const handleRemoveSelectedFile = (e) =>{
    e.preventDefault();
    setLocationFile({});
  }

  const handleUpload = async (event) =>{
    const formData = new FormData();
    formData.append("file", locationFile);
    try{
      setIsLoading(true);
      const responseData = await axios.post(EndPoint.LOCATION_FILE_UPLOAD_API, formData);
      console.log("######## handleUpload #############", responseData);
      setIsLoading(false);
      if(!responseData.data.error){
        prop.handleClose();
        alert(responseData.data.data);
      }
    }catch(err){
      setIsLoading(false);
      console.log("##### EndPoint.LOCATION_FILE_UPLOAD_API #####", err)
      alert(err.response.data.message);
    }
  }
  
  const fileData = () => {
    if (locationFile?.name) {

        return (
            <div>
                <h2>File Details:</h2>
                <p>File Name: {locationFile?.name}</p>

                <p>File Type: {locationFile?.type}</p>

                <p>
                    Last Modified:{" "}
                    {locationFile?.lastModifiedDate?.toDateString()}
                </p>

            </div>
        );
    } else {
        return (
            <div>
              <Form>
                <Form.Group>
                  <Form.Label>Upload file: </Form.Label>
                  <label>
                    <Form.Control type="file" onChange={(e) => handleChange(e)} />
                  </label>
                </Form.Group>
              </Form>
              <br />
              <div className="pdf-drag-drop-text"><div className="pdf-drag-drop-icon"><img src={pdfIcon}/></div>Drag & Drop<br/> or <span>browse</span></div>
            </div>
        );
    }
  };
  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose} size={isLoading? "sm":"md"}> 
        {
          !isLoading &&
          <>
            <Modal.Header closeButton>
              <Modal.Title>Upload Location Sheet</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {
                (locationFile?.name) &&
                <>
                  <div className="pdf-close" onMouseDown={handleRemoveSelectedFile}><img src={CloseIcon}/></div>
                  <br />
                </>
              }
                {fileData()}

            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onMouseDown={prop.handleClose}>CANCEL</Button>
              <Button variant="primary" onMouseDown={handleUpload}>UPLOAD</Button>
            </Modal.Footer>
          </>
        }
        {isLoading?(
          <div className="updating-sheet-modal">
          
            <Modal.Body>
              <div className="text-center mb-3">

              <svg id="Loading" xmlns="http://www.w3.org/2000/svg" width="37.489" height="37.489" viewBox="0 0 37.489 37.489">
                <circle id="Ellipse_2" data-name="Ellipse 2" cx="3.998" cy="3.998" r="3.998" transform="translate(3.394 24.364)" fill="#909ba6"/>
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.47" cy="3.47" r="3.47" transform="translate(29.644 21.875)" fill="#c8d2dc"/>
                <circle id="Ellipse_4" data-name="Ellipse 4" cx="2.866" cy="2.866" r="2.866" transform="translate(27.08 5.582)" fill="#e9edf1"/>
                <ellipse id="Ellipse_5" data-name="Ellipse 5" cx="4.243" cy="4.139" rx="4.243" ry="4.139" transform="translate(0 14.323)" fill="#7e8b96"/>
                <ellipse id="Ellipse_6" data-name="Ellipse 6" cx="3.857" cy="3.758" rx="3.857" ry="3.758" transform="translate(12.574 29.973)" fill="#a2abb8"/>
                <ellipse id="Ellipse_7" data-name="Ellipse 7" cx="3.664" cy="3.568" rx="3.664" ry="3.568" transform="translate(22.679 28.707)" fill="#b9c3cd"/>
                <ellipse id="Ellipse_8" data-name="Ellipse 8" cx="4.435" cy="4.33" rx="4.435" ry="4.33" transform="translate(4.397 4.33)" fill="#5f6c75"/>
                <ellipse id="Ellipse_9" data-name="Ellipse 9" cx="3.278" cy="3.218" rx="3.278" ry="3.218" transform="translate(30.932 13.112)" fill="#dce6eb"/>
                <circle id="Ellipse_10" data-name="Ellipse 10" cx="4.677" cy="4.677" r="4.677" transform="translate(15.162)" fill="#4e5a61"/>
              </svg>
            </div>
            <div className="uploading-text">Uploading sheet</div>
            <div className="uploading-text-sub px-3">
              Please do not refresh or close the window. Because uploading is in progress.
            </div>
          </Modal.Body>
          </div>
        ):null}
      </Modal>
    </>
  );
};
export default FileUploadModal;
