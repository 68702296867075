import React, { useState, useEffect } from "react";
import axios from "axios";
import SubCategory from "../Category/subcategory";
import EndPoint from "../../../api/Endpoint";
// import PdfAdd from "../../PdfAdd";
// import PdfModal from "../../PdfAdd/modal";
import { useParams,Link } from "react-router-dom";
import PDFCategoryModal from "../../CategoryAdd/pdfModal";
import { Button } from "react-bootstrap";
import DeleteModal from "../../CategoryAdd/deleteModal";
import { useHttpClient } from "../../../hooks/HttpHook";
import LeftArrow from "./../../../assets/img/angle-left-solid.svg"
import Constants from "../../../api/Constants";
const SubCategoryList = () => {
  const { categoryId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [subcategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState({});
  const [deleteCaategoryId, setDeleteCaategoryId] = useState(0);
  const [isEdit, setIsEdit] = useState(false);

  const {sendRequest} = useHttpClient();

  const [pdf_category, setPdfCategory] = useState({});
  const [pdf_show, setPdfShow] = useState(false);

  const [pdf_delete_show, setPdfDeleteShow] = useState(false);
  const handlePdfClose = () => setPdfShow(false);
  const handlePdfDeleteClose = () => setPdfDeleteShow(false);
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });

  const [deleteResponseObj, setDeleteResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
  const editPDFFormCategory = (data) => {
    if(data.id === undefined){
      setIsEdit(false);
      console.log('add');
    }
    else{
      setIsEdit(true);
      console.log('edit');
    }
    setResponce({})
    setPdfCategory(data);
    setPdfShow(true);
  };

  const handleDelete = (data) => {
    setDeleteCaategoryId(data.id);
    setPdfDeleteShow(true);
  };

  const handlePdfDelete = () => {
    var token =  localStorage.getItem('token');
    axios
    .delete(EndPoint.DELETE_CATEGORY_PDF_URL+categoryId,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        data: {
          id: deleteCaategoryId
        }
      }
      )
    .then(
      (response) => {
        setIsLoading(false);
        setDeleteResponce({
          show: true,
          message: "PDF deleted successfully",
          type: "success",
        });
        setTimeout(() => {
          setDeleteResponce({
            show: false,
            message: "",
            type: "error",
          });
        }, 2000);
        fetchData();
      
      
      },
      (error) => {
        setIsLoading(false);
        setResponce({
          show: true,
          message: error.response.data.message,
          type: "error",
        });
      }
    )
    .catch((e) => console.log(e));


    setPdfDeleteShow(false);
  };

  //DELETE_CATEGORY_PDF_URL

  const handleBackButton = () => {
    window.location.href= `${EndPoint.FRONT_LOGIN_URL}`;;
  };


  const onCategoryPDFSubmit = (formDataArr) => {
    setIsLoading(true);
    var token =  localStorage.getItem('token');
    var formData = new FormData();
    var imagefile = document.querySelector('#pdf');
    if(formDataArr.id !== undefined){
      formData.append("id",formDataArr.id);

    }
    if(formDataArr.removePDFFile !== undefined){
      formData.append("removePDFFile",formDataArr.removePDFFile);

    }
    formData.append("pdf", imagefile.files[0]);
    formData.append("startDate", formDataArr.startDate);
    formData.append("endDate", formDataArr.endDate);
    var myHeaders = new Headers();
    myHeaders.append("Authorization",`Bearer ${token}`);
    

    axios
      .patch(EndPoint.CHECK_UPDATE_CATEGORY_PDF_URL+categoryId, formDataArr,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }
        )
      .then(
        (response) => {
         
          axios
          .patch(EndPoint.UPDATE_CATEGORY_PDF_URL+categoryId, formData,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
            )
          .then(
            (response) => {
              setIsLoading(false);
              setResponce({
                show: true,
                message: "Merch Grid update Successfully",
                type: "success",
              });
    
              fetchData();
              setPdfCategory({});
              setPdfShow(false);
            
            },
            (error) => {
              setIsLoading(false);
              setResponce({
                show: true,
                message: error.response.data.message,
                type: "error",
              });
            }
          )
          .catch((e) => console.log(e));
        
        },
        (error) => {
          setIsLoading(false);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => console.log(e));
    




  
  };

  

  useEffect(() => {
    fetchData();
  }, [categoryId]);

  const fetchData = async () => {
    try {
      const responseData = await sendRequest(
        EndPoint.SUBCATEGORY_URL + categoryId,
        "GET"
      );
      var pdfs = responseData.data.pdfs;
      setSubCategories(pdfs);
      setCategories(responseData.data);
    } catch (err) {
      console.log(err);
    }
  };

  return (

    <div   key={categories.id}>
      <div className="row category-top align-items-center">
        <span className="col-lg-3 col-3 text-left">
          <Link className="Back-btn" variant="primary" to={`${Constants.FRONT_BASE_PATH}/category`}>
            <span><img src={`${Constants.FRONT_BASE_URL}/angle-left-solid.svg`}/></span>Back
          </Link>
        </span>
        <span className="col-lg-6 col-6 text-center">
        {deleteResponseObj.show ? (
            <div
              className={
                deleteResponseObj.type == "success"
                  ? "alert alert-success"
                  : "alert alert-danger"
              }
              role="alert"
            >
              {deleteResponseObj.message}
            </div>
          ) : (
            ""
          )}

        </span>
        <span className="col-lg-3 col-3 text-right">
          <Button variant="primary" onClick={() => editPDFFormCategory({})}>
            Add NEW PDF
          </Button>
        </span>
      </div>
      
        <div className="row category-bottom">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th className="col-sm category-title" scope="col">
                      {categories.title}
                    </th>
                    <th className="col-sm" scope="col">
                      Start Date
                    </th>
                    <th className="col-sm" scope="col">
                      End Date
                    </th>
                    <th className="col-sm" scope="col"></th>
                    <th className="col-sm" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {categories.title != undefined && subcategories.length == 0 ? (
                    <tr>
                     
                      <td colSpan="5" className="text-center">
                        No PDFs are available
                      </td>
                    </tr>
                  ) : (
                    subcategories.map((item) => (
                      <SubCategory
                        data={item}
                        handlePdfOpen={editPDFFormCategory}
                        handlePdfDelete={handleDelete}
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      
      <PDFCategoryModal
        responseObj={responseObj}
        showModal={pdf_show}
        handleClose={handlePdfClose}
        data={pdf_category}
        isLoading={isLoading}
        isEdit={isEdit}
        onCategoryPDFSubmit={onCategoryPDFSubmit}
      />
      <DeleteModal
        showModal={pdf_delete_show}
        handleClose={handlePdfDeleteClose}
        handlePdfDelete={handlePdfDelete}
      />
      {isLoading?(
        <div className="loaderCoder">
        <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      ):null}
    </div>
  );
};
export default SubCategoryList;
