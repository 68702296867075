import React from "react";
import { Button } from "react-bootstrap";
import {Link} from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import LogoInner from "../../assets/img/logo-inner.svg";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
const Navbar = () => {
  const logoutFb = ()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href= `${Constants.FRONT_BASE_PATH}/admin/login`;;
    
}
  return (
    <nav className="navbar navbar-expand-lg navbar-dark custom-navbar">
      <div className="container">
      <Link className="navbar-brand" to={`${Constants.FRONT_BASE_PATH}/`} >
      <img src={`${Constants.FRONT_BASE_URL}/img/logo-inner.svg`}/>
      </Link>
      

      
        
        <div className="header-right ">
          <Dropdown >
            <Dropdown.Toggle  id="dropdown-basic">
              {JSON.parse( localStorage.getItem('user')).name?JSON.parse( localStorage.getItem('user')).name:'Admin'}
            </Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item >
              {(localStorage.getItem('token') == null)?(
                <Link className="nav-link" to="/login">
                Login
                </Link>
              ):(
                <>
                  <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/user-management-list`}>
                    User List
                  </Link>
                  <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/version-history`}>
                    App version history
                  </Link>
                  <div className="nav-link" onClick={logoutFb}>
                  Logout
                  </div>
                </>
            )}
              </Dropdown.Item>
              
            </Dropdown.Menu>
          </Dropdown>
          
        </div>
      </div>
    </nav>
  );
};
export default Navbar;