import { useState, useEffect } from 'react';
import AddressSuggesstions from './address-suggesstions';
import SearchBar from './search-bar';
import EndPoint from "../../api/Endpoint";
import { useHttpClient } from "../../../src/hooks/HttpHook";
import Constants from '../../api/Constants';
import { useNavigate } from 'react-router-dom';
const AddressWithSearch = () => {
  const [suggesstions, setSuggesstions] = useState([]);
  const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
  const [keyword, setKeyword] = useState('');
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showDropDown, setShowDropDown] = useState(false);
  const [disableSearchBtn, setDisableSearchBtn] = useState(true);
  const navigate = useNavigate();
  
  const updateKeyword = async(keywordArg) => {
    setKeyword(keywordArg);
    let body ={
        search_keyword:keywordArg
    };
    if(keywordArg.length >=3){
      setDisableSearchBtn(false);
        try {
            const responseData = await sendRequest(
                EndPoint.LOCATION_FRONT_API,
                "POST",
                body
            );        
            console.log("##### responseData ######", responseData.data)
            setSuggesstions(responseData.data);
        } catch (err) {
            console.log(err);
        }
    }
  }

  const handleBlur = (status) =>{
    setShowDropDown(status);
  }

  const handleMouseDownSearch = async(event) =>{
    // event.preventDefault();
    if(keyword.length >=3){
      navigate(`${Constants.FRONT_BASE_PATH}/user/user-search-link`, { state: { address: keyword } });
    }
  }
  
  useEffect(() => {
  }, []);

  return (
    <div className='d-flex gap-4'>
      <div className="wrapper">
        {/* {loading && <div>HackerNews frontpage stories loading...</div>}
        {error && <div>{`Problem fetching the HackeNews Stories - ${error}`}</div>} */}
        <SearchBar keyword={keyword} setShowDropDown={handleBlur} onChange={updateKeyword}/>
        <AddressSuggesstions setKeyword={setKeyword} setSuggesstions={setSuggesstions} showDropDown={showDropDown} suggesstions={suggesstions} />
      </div>
      <button onMouseDown={handleMouseDownSearch} className={disableSearchBtn?"orange-btn mr-0":"orange-btn mr-0"}>SEARCH</button>
    </div>
  )
}

export default AddressWithSearch;