import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const DeleteModal = (prop) => {
   const [category,setCategory] = useState({});
   const formTitle = prop.data.title != undefined?'Delete PDF':'Delete PDF';
   const formButtonTitle = prop.data.title != undefined?'CONFIRM':'CONFIRM';

  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose} aria-labelledby="contained-modal-title-vcenter"
      centered className="delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>{formTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this PDF? </p>
          <p>Once it gets deleted, app users will not be able to access it.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={prop.handleClose}>Cancel</Button>
          <Button variant="primary" onClick={prop.handleClose}>{formButtonTitle}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteModal;
