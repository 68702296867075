import ActionTypes from "../constants/action_types";

const InitialState = {
    products:[]
}


export const setProductReducer = (state = InitialState,{type,payload})=>{

      switch (type) {
        case ActionTypes.FETCH_PRODUCTS:
            return {
                ...state,
                products:payload
            }
            break;
        case ActionTypes.SET_PRODUCTS:
            return {
                ...state,
                products:payload
            }
        default:
            return state;
            break;
      }
}

export const selectProductReducer = (state = {},{type,payload})=>{
    switch (type) {
    case ActionTypes.FETCH_PRODUCT:
        return {
            ...state,...payload
        }
    case ActionTypes.ADD_PRODUCT:
          return {
              ...state,...payload
          }
    case ActionTypes.ADD_PRODUCT:
          return {}    
      default:
          return state;
          break;
    }
}