import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Constants from "../../api/Constants";
import EndPoint from "../../api/Endpoint";
const ForgotPasswordModal = (prop) => {
  const path = Constants.FRONT_BASE_URL;
  const [formData, setFormData] = useState({ email: "", current_url: path });
  const [isLoading, setIsLoading] = useState(false);
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  }); 
  const onChangeHandler = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const onSubmit = () => {
    setIsLoading(true);
    axios
      .post(EndPoint.FORGOT_PASSWORD_URL, formData)
      .then(
        (response) => {
          setResponce({
            show: true,
            message: response.data.message,
            type: "success",
          });
          setIsLoading(false);
          setFormData({ email: "", current_url: path });
          
        },
        (error) => {
          setIsLoading(false);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if(!prop.showModal){
      setResponce({
      show: false,
      message: "",
      type: "error",
    });
    }
    else if(!isLoading){
        setTimeout(() => {
          setResponce({
            show: false,
            message: "",
            type: "error",
          });
        }, 2000);
    }

  
  }
    ,[prop.showModal]);
  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose} aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
          <p>Submit your email address to receive a link to reset your password</p>

        </Modal.Header>
        <Modal.Body>
        {responseObj.show ? (
              <div
                className={
                  responseObj.type == "success"
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {responseObj.message}
              </div>
            ) : (
              ""
            )}
          <form id="add-category-form">
          <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  className="form-control"
                  value={formData.email}
                  onChange={onChangeHandler}
                  placeholder="Enter email"
                />
             
              </Form.Group>

          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" disabled={isLoading?true:false} onClick={onSubmit}>SUBMIT</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ForgotPasswordModal;
