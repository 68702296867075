// import React from 'react';
// import { Route, Navigate } from 'react-router-dom';

// export const PrivateRoute = ({ component: Component, roles, ...rest }) => {
//     return(
//         <Route {...rest} render={props => {
//             const currentUser = authenticationService.currentUserValue;
//             if (!currentUser) {
//                 // not logged in so redirect to login page with the return url
//                 return <Navigate to='/login' state={{from: props.location}}/>
//             }
    
//             // check if route is restricted by role
//             if (roles && roles.indexOf(currentUser.role) === -1) {
//                 // role not authorised so redirect to home page
//                 return <Navigate to='/' />
//             }
    
//             // authorised so return component
//             return <Component {...props} />
//         }} />
//     )
// } 

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { authenticationService } from '../_services';
export const PrivateRoute = ({role, Component}) => {
    const currentUser = authenticationService.currentUserValue;
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to='/login' />
    }
    const roleToken = authenticationService.fetchedTokenData();
    console.log("Role of user", role, currentUser, roleToken);
    // check if route is restricted by role
    if (role && role.indexOf(roleToken.role) === -1) {
        // role not authorised so redirect to home page
        return <Navigate to='/' />
    }
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return currentUser ? <Component /> : <Navigate to="/login" />;
}