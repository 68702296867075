const Constants = {
    // BASE_URL:'https://murphy.mobilepricecards.com/api/',
    // FRONT_BASE_URL:'https://murphy.mobilepricecards.com',
    //BASE_URL:'https://qaprayag.mobilepricecards.com/api/',
    //FRONT_BASE_URL:'https://qaprayag.mobilepricecards.com',
    BASE_URL:'https://devprayag.mobilepricecards.com/api/',
    FRONT_BASE_URL:'https://devprayag.mobilepricecards.com',
    FRONT_BASE_PATH:'',
    IMAGE_URL:'',
    PDF_FILE_SIZE_LIMIT:20
}
export default Constants;
