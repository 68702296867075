import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const NoPDFModal = (prop) => {
  
  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>No PDF Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <p>No PDFs are available.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={prop.handleClose}>CLOSE</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NoPDFModal;
