import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { propTypes } from "react-bootstrap/esm/Image";
import Modal from "react-bootstrap/Modal";
const NotFoundModal = (props) => {
  
  return (
    <>
      <Modal show={props.showModal} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Not Found</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <p>{props.messageError} <br /> {props.message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={props.handleClose}>CLOSE</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default NotFoundModal;
