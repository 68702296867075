import HomePage from "./pages/HomePage";
import './App.css';
import './assets/css/front.css';
import {BrowserRouter,Routes,Route,Navigate} from 'react-router-dom'

import LoginPage from "./pages/LoginPage";
import CategoryPage from "./pages/CategoryPage";
import ResetPassword from "./components/Login/resetPassword";
import Constants from "./api/Constants";
import { useState } from "react";
//Category 
import Category from "./components/CategoryList";

// User section
import UserLogin from "./components/UserLogin/userLogin";
import UserResetPassword from "./components/UserLogin/userResetPassword";
import UserForgotPassword from "./components/UserLogin/userForgotPassword";
import UserVerifyemail from "./components/UserLogin/userVerifyemail";
import UserSignup from "./components/UserLogin/userSignup";
import UserChangePassword from "./components/UserLogin/userChangePassword";
import DeActivateUser from "./components/UserLogin/de-activate-user";
import UserPDFpage from "./components/UserCategoryList/categoryPdf";
import UserSearchLinkList from "./components/userSearch/user-search-link";
import PriceTag from "./components/PriceTag";
import UserPriceTag from "./components/UserPriceTag";
import { PrivateRoute } from "./components/PrivateRoute/PrivateRoute";
import UserManagementList from "./components/UserManagement/index";
import VersionHistory from "./components/VersionControl/version-history";
import UserCategoryList from "./components/UserCategoryList";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(
    () => localStorage.getItem('token') !== null
  );
  return (
    <BrowserRouter >
      <Routes> 

      <Route path={`${Constants.FRONT_BASE_PATH}`} element={isLoggedIn?<HomePage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />      
      <Route path={`${Constants.FRONT_BASE_PATH}/login`} element={isLoggedIn?<HomePage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`} />} />
      
      {/* <Route path={`${Constants.FRONT_BASE_PATH}`} element={isLoggedIn?<HomePage />:<Navigate to={`login`}/>} />       */}
      <Route path={`${Constants.FRONT_BASE_PATH}/admin/login`} element={!isLoggedIn?<LoginPage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/`} />} />    
      
      {/* <Route path={`${Constants.FRONT_BASE_PATH}/category/:categoryId`} element={isLoggedIn?<CategoryPage />:<Navigate to={`login`}/>} />   */}
      <Route path={`${Constants.FRONT_BASE_PATH}/reset-password`} element={<ResetPassword />} />
      <Route path={`${Constants.FRONT_BASE_PATH}/category/:categoryId`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={CategoryPage} role={"ADMIN"}/>} /> 

      <Route path={`${Constants.FRONT_BASE_PATH}/category`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={Category} role={"ADMIN"}/>} />  {/* element={isLoggedIn?<Category />:<Navigate to='/login'/>} */}
      <Route path={`${Constants.FRONT_BASE_PATH}/user-management-list`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={UserManagementList} role={"ADMIN"}/>} />
      <Route path={`${Constants.FRONT_BASE_PATH}/category`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={Category} role={"ADMIN"}/>} />  {/* element={isLoggedIn?<Category />:<Navigate to='/login'/>} */}

      <Route path={`${Constants.FRONT_BASE_PATH}/version-history`} element={!isLoggedIn?<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>:<PrivateRoute Component={VersionHistory} role={"ADMIN"}/>} /> 
      {/* User routing provided */}

        <Route path={`${Constants.FRONT_BASE_PATH}/user/login`} element={ !isLoggedIn?<UserLogin />:<Navigate to={`${Constants.FRONT_BASE_PATH}/`} />} />                           
        <Route path={`${Constants.FRONT_BASE_PATH}/user/reset-password/:token`} element={<UserResetPassword />} />  
        <Route path={`${Constants.FRONT_BASE_PATH}/user/forgot-password`} element={<UserForgotPassword />} />  
        <Route path={`${Constants.FRONT_BASE_PATH}/user/verify-email`} element={<UserVerifyemail />} />  
        <Route path={`${Constants.FRONT_BASE_PATH}/user/signup`} element={<UserSignup />} />
        <Route path={`${Constants.FRONT_BASE_PATH}/user/change-password`} element={isLoggedIn?<UserChangePassword />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`}/>} />  
        <Route path={`${Constants.FRONT_BASE_PATH}/user/de-activate-user`} element={ !isLoggedIn?<DeActivateUser />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`}/>} />
        <Route path={`${Constants.FRONT_BASE_PATH}/user/category-pdf`} element={isLoggedIn?<UserPDFpage />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`}/>} />
        <Route path={`${Constants.FRONT_BASE_PATH}/user/user-search-link`} element={ isLoggedIn?<UserSearchLinkList />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`}/>} />
        <Route path={`${Constants.FRONT_BASE_PATH}/price-tag`} element={ isLoggedIn?<PriceTag />:<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>} />
        <Route path={`${Constants.FRONT_BASE_PATH}/user/price-tag`} element={ isLoggedIn?<UserPriceTag />:<Navigate to={`${Constants.FRONT_BASE_PATH}/login`}/>} /> 
        <Route path={`${Constants.FRONT_BASE_PATH}/user/categories`} element={isLoggedIn?<UserCategoryList />:<Navigate to={`${Constants.FRONT_BASE_PATH}/user/login`}/>} />
        <Route
            path="*"
            element={<Navigate to={`${Constants.FRONT_BASE_PATH}/`} replace />}
        />
      </Routes>
  </BrowserRouter>
  );
}

export default App;