import { BehaviorSubject } from 'rxjs';
import jwt_decode from "jwt-decode";
const currentUserSubject = new BehaviorSubject(JSON.parse(JSON.stringify(localStorage.getItem('token'))));

export const authenticationService = {
    fetchedTokenData,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function fetchedTokenData() {
    const tokenData = JSON.parse(JSON.stringify(localStorage.getItem('token')));
    const jwtData = jwt_decode(tokenData);
    if(jwtData){
        return{
            email: jwtData.email,
            role: jwtData.role,
            userId: jwtData.userId
        }
    }
}
