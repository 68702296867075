import cutright from '../../assets/img/cutright-pdf.png';
import cutup from '../../assets/img/cutup-pdf.png';
import ImageTag from '../ImageTag';
import Modal from 'react-bootstrap/Modal';
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";

export default function PrintModal(props){
    const [gridItemArr, setGridItemArr] = useState([]);
    const doc = new jsPDF({
        orientation: "p",
        unit: "in",
        format: [8.5, 11]
    });
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    let itemCheckLastElement = 0;
    const posObj = {0:{x:2,y:0}, 1:{x:5.25,y:0}, 2:{x:2,y:3.25}, 3:{x:5.25,y:3.25}, 4:{x: 2,y: 6.5}, 5:{x:5.25,y:6.5} };
    const widthHeightObj = {width: 3.2, height: 3.25};
    useEffect(()=>{
        let gridItemsSelectedArr = JSON.parse(JSON.stringify(props.girdItemsSelected));
        let lengthOfArr = gridItemsSelectedArr.length;
        if(lengthOfArr >6){
            breakGridItemsSelected();
        }else{
            const gridItemArrTemp=[];
            gridItemArrTemp[0]=gridItemsSelectedArr;
            setGridItemArr(gridItemArrTemp);
        }
        if(props.show){
            printThePDFWithPages();
        }
    },[props.girdItemsSelected, props.show]); 

    const breakGridItemsSelected = () =>{
        let gridItemsSelectedArr = JSON.parse(JSON.stringify(props.girdItemsSelected));
        let chunk = [];
        let tempGridItemArr = [];
        while (gridItemsSelectedArr.length > 0) {
            chunk = gridItemsSelectedArr.splice(0,6);
            if(chunk.length < 6){
                let lengthOfChunk = chunk.length;
                let numOfIterations = 6 - lengthOfChunk;
                for(let i=0;i<numOfIterations;i++){
                    chunk.push({filename:"", image:"", checked:false})
                }
            }
            tempGridItemArr.push(chunk);
        }
        setGridItemArr(tempGridItemArr);
    }

    const printThePDFWithPages = () =>{
        let totalPages = gridItemArr.length -1;
        props.setIsLoading(true);
        try{
            if(props.show){
                if(itemCheckLastElement > 0){
                    doc.addPage([ width, height ], 'p');
                }
                const priceModalScrollId = document.getElementById(`price-modal-scroll-id-${itemCheckLastElement}`);
                if(priceModalScrollId){
                    let image = new Image();
                    const gridItemTempArr = gridItemArr[itemCheckLastElement];
                    html2canvas(priceModalScrollId).then(function(canvas) {
                        const imgData = canvas.toDataURL("image/png");       
                        // doc.addImage(imgData, "JPEG", 0, 0); 
                        gridItemTempArr.forEach((element, index) => {
                            if(element.image != ''){
                                doc.addImage(element.image, "JPEG", posObj[index].x, posObj[index].y, widthHeightObj.width, widthHeightObj.height);    
                            }
                        });
                        doc.setDrawColor(128, 130, 133);
                        doc.setLineWidth(.0104);
                        doc.setLineDash([.0104, .0308], 0);
                        doc.line(2, 0, 2, 9.75); 
                        // doc.setLineWidth(.0208);
                        doc.line(5.25, 0, 5.25, 9.75);
                        // doc.setLineWidth(.0208);
                        doc.line(2, 3.25, 8.45, 3.25); 
                        // doc.setLineWidth(.0208);
                        doc.line(2, 6.5, 8.45, 6.5);
                        doc.line(2, 9.75, 8.45, 9.75);
                        doc.addImage(cutright, "PNG", 1.40, 3.12, 0.52, 0.24);
                        doc.addImage(cutright, "PNG", 1.40, 6.40, 0.52, 0.24);
                        doc.addImage(cutright, "PNG", 1.40, 9.62, 0.52, 0.24);
                        doc.addImage(cutup, "PNG", 1.88, 9.85, 0.24, 0.52);
                        doc.addImage(cutup, "PNG", 5.138, 9.85, 0.24, 0.52);
                        doc.setFont("times", "bold");
                        doc.setFontSize(18);
                        doc.setTextColor(128, 130, 133);
                        doc.text(.4, 1.05, 'Cut each');
                        doc.text(.4, 1.33, 'price tag at');
                        doc.text(.4, 1.61, 'the dotted');
                        doc.text(.4, 1.89, 'line(s).');

                        doc.text(.4, 4.36, 'Corte cada');
                        doc.text(.4, 4.64, 'etiqueta de');
                        doc.text(.4, 4.92, 'precio por');
                        doc.text(.4, 5.2, 'las línea(s)');
                        doc.text(.4, 5.48, 'de puntos.');
                        
                        if(itemCheckLastElement === totalPages){
                            itemCheckLastElement = 0;
                            doc.save("download.pdf");
                            props.setPrintPdf(false);
                            props.setIsLoading(false);
                        }else{
                            itemCheckLastElement = itemCheckLastElement+1;
                            printThePDFWithPages();
                        }
                    });
                }
            }
        }catch(err){
            props.setPrintPdf(false);
            props.setIsLoading(false);
            console.log("Error printing the PDF", err);
        }
    }

    return(
        <div className='price-tag-modal print-modal'>              
        <div className='modal-dialog'>
            {
                gridItemArr.map((element,index)=>{
                    return(
                        <div id={`price-modal-scroll-id-${index}`} className={props.show?"row price-modal-scroll zero-height":"row price-modal-scroll zero-height"}>
                            <div className="col-md-3 position-relative">
                            <div className="left-title-of-pdf">Cut each price tag at the dotted line(s).</div>
                            <div className='modal-left-icon'>
                                <ImageTag src={cutright} /> 
                            </div>
                            <div className="left-title-of-pdf">Cut each price tag at the dotted line(s).</div>
                            <div className='modal-left-icon modal-left-icon2'>
                                <ImageTag src={cutright} /> 
                            </div>
                            <div className="left-title-of-pdf"></div>
                            <div className='modal-left-icon modal-left-icon3'>
                                <ImageTag src={cutright } /> 
                            </div>
                            </div>
                            <div className="col-md-9 ">
                            <div className="row">
                            {element.map((elemData, index) => {
                                return(
                                <div className="col-md-6 price-preview-border p-0">
                                    <div className="price-preview-right-side-cover">
                                    {/* {elemData.image != '' &&
                                    <>                      
                                        <ImageTag src={elemData.image}/> 
                                    </>
                                    } */}
                                    </div>
                                </div>
                                )
                            })}
                            </div>
                            </div>
                            <div className='modal-bottom-icon '>
                            <ImageTag src={cutup} /> 
                            </div>
                            <div className='modal-bottom-icon modal-left-icon3'>
                            <ImageTag src={cutup} /> 
                            </div>
                        </div>
                    )
                }
                )                   
            }
        </div>
        </div>
    ); 
}