import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import DeleteModal from "./modal";
const SubCategory = (props) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = ()=> setShow(true);
  
    const { id,pdfUrl,pdfName,startDate,endDate } = props.data;
    return(
        <tr key={id}>
        <td>{pdfName}</td>
        <td className='full_text'>{startDate}</td>
        <td className='full_text'>{endDate}</td>
        <td>

          <Button onClick={()=>{props.handlePdfOpen(props.data);} } className="btn btn-secondary">
            EDIT 
          </Button>
          </td>
        <td>
          <Button  onClick={()=>{props.handlePdfDelete(props.data);} }    className="btn btn-default">
            Delete
          </Button>

          <DeleteModal showModal={show} handleClose={handleClose} data='' />
        </td>
      </tr>
    )
}
export default SubCategory;