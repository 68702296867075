import axios from "axios";
import { useEffect, useState } from "react";
import EndPoint from "../../api/Endpoint";
import { Button, Form } from "react-bootstrap";
import ForgotPasswordModal from "./modal";
import logo from "../../assets/img/logo.png";
import Constants from "../../api/Constants";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("token") != null) {
      window.location.href= `${Constants.FRONT_BASE_URL}`;;
    }
  }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);


  const [user, setUser] = useState({ email: "", password: "" });
  const [responseObj, setResponce] = useState({
    show: false,
    message: "",
    type: "error",
  });
 
  const onChangeHandler = (event) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };


  const onResetPassword = () => {
    setShow(true)
  };
  const today = new Date();
  const year = today.getFullYear();
  //Ch019@test.com
  const onSubmit = () => {
    setIsLoading(true);

    axios
      .post(EndPoint.LOGIN_URL, user)
      .then(
        (response) => {
          console.log("success", response);
          setResponce({
            show: true,
            message: "Login Successfully",
            type: "success",
          });
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("user", JSON.stringify(response.data.data));
          setUser({ email: "", password: "" });
          setIsLoading(false);
          window.location.href= `${Constants.FRONT_BASE_URL}`;
        },
        (error) => {
          setIsLoading(false);
          setResponce({
            show: true,
            message: error.response.data.message,
            type: "error",
          });
        }
      )
      .catch((e) => console.log(e));
  };

  return (
    <div className="main">
      <section className="signup">
        <div className="container">
        <h2 className="text-center logo-img"><img src={logo}/></h2>
          <div className="signup-content">
            {responseObj.show ? (
              <div
                className={
                  responseObj.type == "success"
                    ? "alert alert-success"
                    : "alert alert-danger"
                }
                role="alert"
              >
                {responseObj.message}
              </div>
            ) : (
              ""
            )}
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Control
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={onChangeHandler}
                  placeholder="Enter email"
                />
           
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  name="password"
                  value={user.password}
                  onChange={onChangeHandler}
                  placeholder="Enter Password"
                />
              </Form.Group>
            
              <Button disabled={isLoading?true:false} className="full-width" variant="primary" onClick={onSubmit}  type="button">
                      Login
                    </Button>
              
              <div className="text-center signup-footer-link">
                        <a href="javascript:void(0)" onClick={onResetPassword}>Forgot password</a>
              </div>
            </Form>
          </div>
        </div>
      </section>
      <footer className="footer-section">
        <div className="container">
            © {year} DISH Wireless L.L.C. All rights reserved.
        </div>
    </footer>
      <ForgotPasswordModal showModal={show} handleClose={handleClose}  />
      {/* <ForgotModal showModal={show} handleClose={handleClose} data='' /> */}

    </div>
    // <div className="main">
    // <section className="signup">
    // <div className="container">
    // <h2 className="text-center">Login</h2>
    // <div className="signup-content">

    // <form id="signup-form" className="signup-form">
    // {
    // responseObj.show?
    // <div className={responseObj.type == 'success'?'alert alert-success':'alert alert-danger'} role="alert">
    //   {responseObj.message}
    // </div>:''
    // }

    // <div className="form-group">
    // <label for="last_name">Email</label>
    // <input type="text" className="form-input" name="email" value={user.email} onChange={onChangeHandler} id="email" />
    // </div>

    // <div className="form-group">
    // <label for="password">Password</label>
    // <input type="password" className="form-input" name="password" value={user.password} onChange={onChangeHandler} id="password" />
    // </div>

    // <div className="form-row">
    // <div className="form-group">
    //    <p ><Link to='/register'>Reset Password</Link></p>
    // </div>
    // <div className="form-group">
    // <input type="button" name="submit" id="submit" onClick={onSubmit} className="form-submit" value="Submit" />
    // </div>
    // </div>
    // </form>
    // </div>
    // </div>
    // </section>
    // </div>
    
  );
};

export default Login;
