import { useState } from "react";
import { useHttpClient } from "../../hooks/HttpHook";
import EndPoint from "../../api/Endpoint";
const listIcon= <svg xmlns="http://www.w3.org/2000/svg" width="13.495" height="11.566" viewBox="0 0 13.495 11.566">
                  <path id="bars-solid" d="M0,64.964A.963.963,0,0,1,.964,64H12.531a.964.964,0,0,1,0,1.928H.964A.963.963,0,0,1,0,64.964Zm0,4.82a.963.963,0,0,1,.964-.964H12.531a.964.964,0,0,1,0,1.928H.964A.963.963,0,0,1,0,69.783ZM13.495,74.6a.963.963,0,0,1-.964.964H.964a.964.964,0,0,1,0-1.928H12.531A.963.963,0,0,1,13.495,74.6Z" transform="translate(0 -64)" fill="currentColor"/>
                </svg>
const gridIcon= <svg xmlns="http://www.w3.org/2000/svg" width="11.988" height="11.988" viewBox="0 0 11.988 11.988"><path id="grid-solid" d="M0,33.07A1.07,1.07,0,0,1,1.07,32H2.355a1.07,1.07,0,0,1,1.07,1.07v1.284a1.07,1.07,0,0,1-1.07,1.07H1.07A1.07,1.07,0,0,1,0,34.355Zm0,4.282a1.07,1.07,0,0,1,1.07-1.07H2.355a1.07,1.07,0,0,1,1.07,1.07v1.284a1.07,1.07,0,0,1-1.07,1.07H1.07A1.07,1.07,0,0,1,0,38.636Zm3.425,4.282v1.284a1.07,1.07,0,0,1-1.07,1.07H1.07A1.07,1.07,0,0,1,0,42.918V41.634a1.07,1.07,0,0,1,1.07-1.07H2.355A1.07,1.07,0,0,1,3.425,41.634Zm.856-8.563A1.07,1.07,0,0,1,5.352,32H6.636a1.07,1.07,0,0,1,1.07,1.07v1.284a1.07,1.07,0,0,1-1.07,1.07H5.352a1.07,1.07,0,0,1-1.07-1.07Zm3.425,4.282v1.284a1.07,1.07,0,0,1-1.07,1.07H5.352a1.07,1.07,0,0,1-1.07-1.07V37.352a1.07,1.07,0,0,1,1.07-1.07H6.636A1.07,1.07,0,0,1,7.707,37.352ZM4.282,41.634a1.07,1.07,0,0,1,1.07-1.07H6.636a1.07,1.07,0,0,1,1.07,1.07v1.284a1.07,1.07,0,0,1-1.07,1.07H5.352a1.07,1.07,0,0,1-1.07-1.07Zm7.707-8.563v1.284a1.07,1.07,0,0,1-1.07,1.07H9.634a1.07,1.07,0,0,1-1.07-1.07V33.07A1.07,1.07,0,0,1,9.634,32h1.284A1.07,1.07,0,0,1,11.988,33.07ZM8.563,37.352a1.07,1.07,0,0,1,1.07-1.07h1.284a1.07,1.07,0,0,1,1.07,1.07v1.284a1.07,1.07,0,0,1-1.07,1.07H9.634a1.07,1.07,0,0,1-1.07-1.07Zm3.425,4.282v1.284a1.07,1.07,0,0,1-1.07,1.07H9.634a1.07,1.07,0,0,1-1.07-1.07V41.634a1.07,1.07,0,0,1,1.07-1.07h1.284A1.07,1.07,0,0,1,11.988,41.634Z" transform="translate(0 -32)" fill="currentColor"/></svg>
export default function PriceTagModal(){

    const [radioValue, setRadioValue] = useState('en');
    const [viewByValue, setViewByValue] = useState('1');
  
    const [show, setShow] = useState(false);
    const [printPdf, setPrintPdf] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isLoader, setIsLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [allListData, setAllListData] = useState([]);
    const [girdItems, setGirdItems] = useState([]);
    const [mobileArr, setMobileArr] = useState([]);
    const [searchStr, setSearchStr] = useState('');
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const radios = [
        { name: 'EN', value: 'en' },
        { name: 'ES', value: 'es' },
    ];
    const viewBy = [
        { name: 'List', value: '1', icon: listIcon },
        { name: 'Grid', value: '2', icon: gridIcon },
    ];

    const gridDataSelected = [
        { id: 1, filename:'', checked: false, image: '' },
        { id: 2, filename:'', checked: false, image: '' },
        { id: 3, filename:'', checked: false, image: '' },
        { id: 4, filename:'', checked: false, image: '' },
        { id: 5, filename:'', checked: false, image: '' },
        { id: 6, filename:'', checked: false, image: '' }
    ];

    const [girdItemsSelected, setGirdItemsSelected] = useState(gridDataSelected);
    const [propsForNotFound, setPropsForNotFound] = useState({show:false, message:"", messageError:""});

    const fetchInitialListData = async() =>{
        setIsLoading(true);
        try {
            const responseData = await sendRequest(
                EndPoint.INDEX_JSON_CATEGORY_FRONT_API
            );
            setIsLoading(false);
            let responseDataVar = JSON.parse(JSON.stringify(responseData.data));
            responseDataVar = responseDataVar.map(element=>{
                element.checked = false;
                return element;
            })
            setAllListData(responseDataVar);
            filteredListData(responseDataVar);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    }
    /**
     * param allIndexData -> Array fetched from index json
     * Filter out the allIndexData array based on filter condition.
     */
    const filteredListData = (allIndexData, language='en', mobile=[], searchString='') => {
        const tempArr = JSON.parse(JSON.stringify(allIndexData));
        let indexArr = tempArr.filter((element)=>element.lang === language);
        if(mobile.length > 0){
            indexArr = indexArr.filter((element)=>mobile.includes(element.OsName));
        }
        if(searchString != ''){
            const searchStringVar = searchString.toLowerCase();
            indexArr = indexArr.filter((element)=>element.name.toLowerCase().search(searchStringVar) >=0);
        }
        checkTheStatusOfCheckBox(indexArr, 'list');
        // setListData(indexArr);
        if(viewByValue == 2){
            fetchImagesFromAWS(2, indexArr);  
        }
    }

    const checkTheStatusOfCheckBox = (indexArr, type) =>{
        const girdItemsArr = JSON.parse(JSON.stringify(girdItemsSelected));
        let indexArrTemp = JSON.parse(JSON.stringify(indexArr));
        indexArrTemp = indexArr.map((element)=>{
            let girdItemsArrTemp = girdItemsArr.filter(ele=> ele.filename === element.filename);
            if(girdItemsArrTemp.length>0){
                element.checked = true;
            }
            return element;
        });
        setListData(indexArrTemp);
    }

    const fetchImagesFromAWS = async(value, listDataArr=[]) =>{        
        if(value == 2){
            const body = {
                fileinfo: JSON.parse(JSON.stringify(listDataArr))
            }
            setIsLoading(true);
            try {
                const responseData = await sendRequest(
                    EndPoint.MULTIPLE_IMAGE_CATEGORY_FRONT_API,
                    "POST",
                    body
                );
                setIsLoading(false);
                setGirdItems(responseData.data)
            } catch (err) {
                console.log(err);
                setGirdItems([]);
                setIsLoading(false);
            }
        }
    }

    const handleGridChange = (value) =>{
        setViewByValue(value);
        fetchImagesFromAWS(value, listData);
    }

    const handleRadioChange = (valueArg) => {
        setRadioValue(valueArg);
        filteredListData(allListData, valueArg, mobileArr, searchStr);
    }

    const handleMobileSelection = (event) => {
        const tempArr = JSON.parse(JSON.stringify(mobileArr));
        if(event.target.checked){
            tempArr.push(event.target.value);
            setMobileArr(tempArr);
            filteredListData(allListData, radioValue, tempArr, searchStr);
        }else{
            const filterArr = tempArr.filter(element => element !== event.target.value);
            setMobileArr(filterArr);
            filteredListData(allListData, radioValue, filterArr, searchStr);
        }
    }

    const handleOnChangeSearch = (event) =>{
        // console.log("##### handleOnChangeSearch #####", event.target.value);
        setSearchStr(event.target.value);
        filteredListData(allListData, radioValue, mobileArr, event.target.value);
    }

    const handleCheckChange = (event, type) => {
        const valueOfKey = event.target.value;
        let listDataTmp = JSON.parse(JSON.stringify(listData));
        let gridItemsDataTmp = JSON.parse(JSON.stringify(girdItems));
        const girdItemsSelectedVar = JSON.parse(JSON.stringify(girdItemsSelected));
        const filteredArray = girdItemsSelectedVar.filter((element)=> element.filename === valueOfKey);
        if(filteredArray.length === 0 && event.target.checked){
            fetchSingleImagesFromAWS(valueOfKey);
        }
        if(type==='list'){
            let listDataTemp = listDataTmp.map(element=>{
                if(element.filename === valueOfKey){
                    element.checked = event.target.checked;
                    return element;
                }else{
                    return element;
                }
            })
            setListData(listDataTemp);
        }else{
            let listDataTemp = gridItemsDataTmp.map(element=>{
                if(element.filename === valueOfKey){
                    element.checked = event.target.checked;
                    return element;
                }else{
                    return element;
                }
            })
            setListData(listDataTemp);
            setGirdItems(listDataTemp);
        }
        if(!event.target.checked){
            closeMultipleImages(valueOfKey);
        }
    };
    const closeMultipleImages = (valueOfKey) =>{
        let indexArr = [];
        let indexNotBlank = [];
        const girdItemsSelectedTemp = girdItemsSelected.map((element,index)=>{
            if(element.filename === valueOfKey){
                indexArr.push(index)
            }else{
                indexNotBlank.push(index)
            }
            return element;
        })
        const girdItemsSelectedVar = JSON.parse(JSON.stringify(girdItemsSelected)); 

        for(let i =0;i<indexArr.length;i++){
            let runTimeObj={};
            const index =indexArr[i];
            runTimeObj.id = girdItemsSelectedVar[index].id;
            runTimeObj.image = '';
            runTimeObj.filename = '';
            runTimeObj.checked = false;
            girdItemsSelectedVar[index] = runTimeObj; 
        }
        for(let i = 0; i<girdItemsSelectedVar.length;i++){
            const index = indexNotBlank[i];
            let runTimeObj={};
            if(i <= (indexNotBlank.length -1)){
                girdItemsSelectedVar[i].image = girdItemsSelectedVar[index].image;
                girdItemsSelectedVar[i].filename = girdItemsSelectedVar[index].filename;
                girdItemsSelectedVar[i].checked = girdItemsSelectedVar[index].checked;
            }else{
                runTimeObj.id = girdItemsSelectedVar[i].id;
                runTimeObj.image = '';
                runTimeObj.filename = '';
                runTimeObj.checked = false;
                girdItemsSelectedVar[i] = runTimeObj;
            }
        }
        console.log("##### closeMultipleImages ######", girdItemsSelectedVar);
        removeBlankIndexOfImage(girdItemsSelectedVar);
        // setGirdItemsSelected(girdItemsSelectedVar);    
    }

    const handleMouseDown = (e, filename, type, image) =>{
        if(type === 'grid' && image ==='Not found'){
            return;
        }
        const listDataTmp = JSON.parse(JSON.stringify(listData));
        const gridItemsDataTmp = JSON.parse(JSON.stringify(girdItems));
        if(type==='list'){
            let listDataTemp = listDataTmp.map(element=>{
                if(element.filename === filename){
                    element.checked = true;
                    return element;
                }else{
                    return element;
                }
            })
            setListData(listDataTemp);
        }else{
            let listDataTemp = gridItemsDataTmp.map(element=>{
                if(element.filename === filename){
                    element.checked = true;
                    return element;
                }else{
                    return element;
                }
            })
            setListData(listDataTemp);
            setGirdItems(listDataTemp);
        }
        fetchSingleImagesFromAWS(filename);
    }

    const fetchSingleImagesFromAWS = async(value) =>{        
        setIsLoading(true);
        const girdItemsSelectedVar = JSON.parse(JSON.stringify(girdItemsSelected));
        let runTimeObj = {};
        try {
            const responseData = await sendRequest(
                EndPoint.SINGLE_IMAGE_CATEGORY_FRONT_API+`/${value}`,
            );
            setIsLoading(false);
            if(!responseData.error){
                const index = girdItemsSelectedVar.findIndex(item => item.image === '' && item.filename === '');

                runTimeObj.id = index === -1 ? girdItemsSelectedVar.length + 1: index+1;
                runTimeObj.image = responseData.data;
                runTimeObj.filename = responseData.fileName;
                runTimeObj.checked = true;
                if(index === -1){
                    girdItemsSelectedVar.push(runTimeObj);
                }else{
                    girdItemsSelectedVar[index]=runTimeObj;
                }
                setGirdItemsSelected(girdItemsSelectedVar);
            }
    
        } catch (err) {
            console.log(err);
            setIsLoading(false);
            setPropsForNotFound({show:true, message:`File or key: ${value}`, messageError:`${err}!`});
            let listDataTmp = JSON.parse(JSON.stringify(listData));
            let listDataTemp = listDataTmp.map(element=>{
                if(element.filename === value){
                    element.checked = false;
                    return element;
                }else{
                    return element;
                }
            })
            setListData(listDataTemp);
        }
    }

    const handleMouseDownCloseBtn = (elemData, index) =>{
        const girdItemsSelectedVar = JSON.parse(JSON.stringify(girdItemsSelected));
        const filteredArray = girdItemsSelectedVar.filter((element)=> element.filename === elemData.filename); 
        const indexBlank = girdItemsSelectedVar.findIndex(item => item.image === '' && item.filename === '');
        const gridItemsDataTmp = JSON.parse(JSON.stringify(girdItems));
        if(filteredArray.length == 1){
            let listDataTmp = JSON.parse(JSON.stringify(listData));
            let listDataTemp = listDataTmp.map(element=>{
                if(element.filename === elemData.filename){
                    element.checked = false;
                    return element;
                }else{
                    return element;
                }
            })
            let gridDataTemp = gridItemsDataTmp.map(element=>{
                if(element.filename === elemData.filename){
                    element.checked = false;
                    return element;
                }else{
                    return element;
                }
            })
            setGirdItems(gridDataTemp);
            setListData(listDataTemp);
        }
        if(indexBlank === index+1){
            girdItemsSelectedVar[index] = { id: (index+1), filename:'', checked: false, image: '' };
            removeBlankIndexOfImage(girdItemsSelectedVar);
            // setGirdItemsSelected(girdItemsSelectedVar);
        }else if(indexBlank === -1){
            const lastIndex = girdItemsSelectedVar.length-1;
            for(let i = index; i<lastIndex;i++){
                girdItemsSelectedVar[i] = { id: girdItemsSelectedVar[i].id, filename:girdItemsSelectedVar[i+1].filename, checked: girdItemsSelectedVar[i+1].checked, image: girdItemsSelectedVar[i+1].image };
            }
            girdItemsSelectedVar[lastIndex] = { id: (lastIndex+1), filename:'', checked: false, image: '' };
            removeBlankIndexOfImage(girdItemsSelectedVar);
            // setGirdItemsSelected(girdItemsSelectedVar);
        }
        else{
            for(let i = index; i<indexBlank;i++){
                girdItemsSelectedVar[i] = { id: girdItemsSelectedVar[i].id, filename:girdItemsSelectedVar[i+1].filename, checked: girdItemsSelectedVar[i+1].checked, image: girdItemsSelectedVar[i+1].image };
            }
            removeBlankIndexOfImage(girdItemsSelectedVar);
            // setGirdItemsSelected(girdItemsSelectedVar);    
        }
    }

    const removeBlankIndexOfImage = (gridItemsSelectedVar) =>{
        const tempGridItem = JSON.parse(JSON.stringify(gridItemsSelectedVar));
        let tempSlicedArr = gridItemsSelectedVar.slice(0,6);
        if(tempGridItem.length > 6){
            const tempGridSlice = tempGridItem.slice(6,(tempGridItem.length));
            tempGridSlice.map(element=>{
                if(element.image !== ''){
                    tempSlicedArr.push(element);
                }
            })
        }
        setGirdItemsSelected(tempSlicedArr);
    }
    const handlePrint = () =>{
        setPrintPdf(true);
    }
    const handleCloseNotFound = () =>{
        setPropsForNotFound({show: false, message: '', messageError: ''});
    }
    return{
        girdItems,
        listData,
        radioValue,
        viewByValue,
        radios,
        viewBy, 
        girdItemsSelected,
        show,
        isLoader,
        printPdf,
        propsForNotFound,
        setPrintPdf,
        handlePrint,
        setViewByValue, 
        setRadioValue,
        handleClose,
        handleShow,
        fetchInitialListData,
        fetchImagesFromAWS,
        handleRadioChange,
        handleGridChange,
        handleMobileSelection,
        handleOnChangeSearch,
        handleCheckChange,
        handleMouseDownCloseBtn,
        setGirdItemsSelected,
        handleMouseDown,
        setIsLoading,
        handleCloseNotFound
    }

}