import { useEffect, useState } from "react";
import { useHttpClient } from "../../../src/hooks/HttpHook";
import EndPoint from "../../api/Endpoint";
import { useNavigate } from 'react-router-dom';
const AddressSuggesstions = ({suggesstions = [], showDropDown, setKeyword, setSuggesstions}) => {
    const [show, setShow] = useState(true);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const navigate = useNavigate();
    useEffect(()=>{
        setShow(true);
    },[suggesstions]);
    useEffect(()=>{
      setShow(showDropDown);
    },[showDropDown]);
      
    const handleMouseDown = async(e, address) =>{
      e.preventDefault();
      console.log("####### handleMouseDown AddressSuggesstions ########");
      setShow(false);
      setKeyword(address);
      setSuggesstions([]);
      // navigate('/user/user-search-link', { state: { address: address } });        
    }
    return (    
      (suggesstions.length > 0 && show) &&
      <div className="address-wrapper">
        {suggesstions &&
          suggesstions.map(( address ) => (
            <div className='address-list' key={address}>
              <div onMouseDown={(e)=>handleMouseDown(e, address)}>{address}</div>
            </div>                        
          ))}
      </div>
    );
  };
  
  export default AddressSuggesstions;