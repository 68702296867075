import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const DeleteModal = (prop) => {
  
  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <p>Are you sure you want to delete this PDF? <br /> Once it gets deleted, app users will not be able to access it.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={prop.handleClose}>CANCEL</Button>
          <Button variant="primary" onClick={prop.handlePdfDelete}>CONFIRM</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteModal;
