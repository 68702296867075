import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import LogoInner from "../../assets/img/logo-inner.png";
import EndPoint from "../../api/Endpoint";
// import headerLogoNew from "../../assets/img/header-logo-new.png";
import headerLogoNew from "../../assets/img/logo-inner.svg";
import ImageTag from "../ImageTag";
import { useHttpClient } from "../../../src/hooks/HttpHook";
import AddressWithSearch from "./search-bar-parent";
import jwt_decode from "jwt-decode";
import Constants from "../../api/Constants";
const UserNavbar = () => {
    const navigate = useNavigate();
    const { sendRequest } = useHttpClient();
    const logoutFb = async() => {
        const tokenData = JSON.parse(JSON.stringify(localStorage.getItem('token')));
        // Decode the JWT token
        const jwtData = await jwt_decode(tokenData);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        if(jwtData){
            if(jwtData.role === "ADMIN"){
                window.location.href = `${Constants.FRONT_BASE_PATH}/login`;
            }else if(jwtData.role === "FRONT"){
                window.location.href = `${Constants.FRONT_BASE_PATH}/user/login`;
            }
        }else{
            window.location.href = `${Constants.FRONT_BASE_PATH}/user/login`;
        }
        
    }
    const handleMouseDown = (event) => {
        event.preventDefault();
        navigate(`${Constants.FRONT_BASE_PATH}/`);
    }


    return (
 
        <header className="header-outer user-front-nav-bar" style={{background: '#F36C21'}}>
            <div className="container">
                <div className="row">
                    <div className="align-items-center all-header-in d-flex flex-wrap gap-4 justify-content-between w-100">
                        <div className="head-logo-new mr-auto" onMouseDown={handleMouseDown}>
                            <ImageTag src={headerLogoNew} />
                        </div>
                        <div className="drop-search-outer d-flex align-items-center  order-lg-last">
                            <div className="dropdown-cover">
                                <Dropdown alignRight>
                                    <Dropdown.Toggle id="dropdown-basic">
                                    <span className="d-sm-none d-inline-flex align-text-top">
                                            <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"/></svg>
                                        </span>
                                        <span className="text-truncate mw-150 d-sm-inline-block d-none align-middle">
                                            {JSON.parse(localStorage.getItem('user')).name ? JSON.parse(localStorage.getItem('user')).name : 'Admin'}
                                        </span>   
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item >
                                            {(localStorage.getItem('token') == null) ? (
                                                <Link className="nav-link" to="/login">
                                                    Login
                                                </Link>
                                            ) : (
                                                <div>
                                                    <Link className="nav-link" to={`${Constants.FRONT_BASE_PATH}/user/change-password`}>
                                                        Change Password
                                                    </Link>
                                                    <div className="nav-link" onClick={logoutFb}>
                                                        Logout
                                                    </div>
                                                </div>

                                            )}
                                        </Dropdown.Item>

                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="search-inner d-flex">
                            <AddressWithSearch/>
                        </div>
                    </div>
                </div>
            </div>

        </header>
        //     </div>
        // </nav>
    );
};
export default UserNavbar;