import React, { useEffect } from "react";

import SubCategoryList from "../../components/CategoryList/SubCategory";
import Navbar from "../../components/NavBar";
import { redirect } from "react-router-dom";
import Constants from "../../api/Constants";

const CategoryPage = () => {
  useEffect(() => {
    if(localStorage.getItem('token') == null){
      redirect(`${Constants.FRONT_BASE_URL}/login`);
    }

  }, []);
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <Navbar />
      <section className="page-inner-cover">
        <div className="container">
          <SubCategoryList />
        </div>
      </section>
      <footer className="footer-section">
        <div className="container">
            © {year} DISH Wireless L.L.C. All rights reserved.
        </div>
    </footer> 
    </>
  );
};
export default CategoryPage;
