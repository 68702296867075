import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Row, Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/esm/Col";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { Document, Page, pdfjs } from "react-pdf";
import pdfIcon from "../../assets/img/file-plus-regular.svg";
import CloseIcon from "../../assets/img/modal-close-icon.svg";
import moment from "moment";
import EndPoint from "../../api/Endpoint";
import Constants from "../../api/Constants";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFCategoryModal = (prop) => {
  const data= prop.data;
  const [PDFCategory, setPDFCategory] = useState("");
  const [formData, setFormData] = useState(data);
  const formTitle = prop.data.id != undefined ? "Edit PDF" : "Upload PDF";
  const formButtonTitle = prop.data.id != undefined ? "Submit" : "Add";
  const [numPages, setNumPages] = useState(null);
  const [removePDFFile, setRemovePDFFile] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const responseObj = prop.responseObj;
  const [removeOld, setRemoveOld] = useState(false);
  const [renderPdf, setRenderPdf] = useState(false);

  const handleChange = (event) => {
    if(!event.target.files[0]){
      return;
    }
    if(event.target.files[0].type == "application/pdf"){
       console.log(Constants.PDF_FILE_SIZE_LIMIT);
       console.log(event.target.files.length);
       if (event.target.files.length > 0) {
        console.log(event.target.files.length);
        const fileSize = event.target.files.item(0).size;
        const fileMb = fileSize / (1024);
        console.log(fileMb);
        if (fileMb > (Constants.PDF_FILE_SIZE_LIMIT * 1024)) {
          alert('The PDF file size cannot be greater than 20 MB.');
        } else {
          setRemoveOld(true);
          setPDFCategory(event.target.files[0]);
        }
      }



     
    }
    else{
    alert('Please upload file having extensions.pdf only.');
    }
  };

  const removePDF = (event) => {
    event.preventDefault();
    setNumPages(null);
    setPDFCategory('');
    setNumPages(null);
    setRemovePDFFile(true);
    setFormData({ ...formData, removePDFFile:true});

  };

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(".react-pdf__Page__textContent");
      textLayers.forEach(layer => {
        const { style } = layer;
        style.top = "0";
        style.left = "0";
        style.transform = "";
    });
  }

  const resetPDF = () => {
   // setPDFCategory('');
    setNumPages(null);
  };

  useEffect(() => {
    if(!prop.showModal){
      setNumPages(null);
      setRenderPdf(false);
      setStartDate(new Date());
      setRemoveOld(false);
      setEndDate(new Date());
      setFormData({});
      setPDFCategory('');
      setNumPages(null);
      setRemovePDFFile(false);

    }
    else{
    if(data.id == undefined){
      setNumPages(null);
     // setRemovePDFFile(true);


    }
    else if(!removePDFFile){
      setNumPages(null);
      data.removePDFFile = removePDFFile;

      setFormData(data);
      setStartDate(new Date(data.startDate));
      setEndDate(new Date(data.endDate));
      if(data.pdfUrl !== '' && !removeOld){
        setPDFCategory(Constants.BASE_URL+data.pdfUrl);
      }

    } 
  }

     
  }, [data,PDFCategory,removeOld,prop.showModal]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setRenderPdf(false);
    setFormData({ ...formData,startDate:startDate,endDate:endDate,removePDFFile:false});
    setNumPages(1);
  };

  const onChangeHandler = (date,field) => {
    var d = moment(date).unix();
    var se = moment(endDate).unix();
    if(field == 'startDate'){
      if(d > se){
        setEndDate(date);
      }
      setStartDate(date);
    } 
    if(field == 'endDate'){
      setEndDate(date);
    } 
    formData.removePDFFile = removePDFFile;
    setFormData({ ...formData, [field]:moment(date).format('YYYY-MM-DD') });
  };

  const [hovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(true);
  const handleDrop = () => setHovered(false);

  
  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose} backdrop="static" aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>{formTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {responseObj.show ? (
            <div
              className={
                responseObj.type == "success"
                  ? "alert alert-success"
                  : "alert alert-danger"
              }
              role="alert"
            >
              {responseObj.message}
            </div>
          ) : (
            ""
          )}
          <Form>
            <Form.Row>
              <Form.Group className="col-12 col-sm-6 col-md-6" as={Col} controlId="formGridStartDate">
                <Form.Label>Start Date</Form.Label>
                <DatePicker
                  selected={startDate}
                  onChange={(data)=>onChangeHandler(data,'startDate')}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  className="form-control"
                />
              </Form.Group>

              <Form.Group className="col-12 col-sm-6 col-md-6" as={Col} controlId="formGridEndDate">
                <Form.Label>End Date</Form.Label>
                <DatePicker
                  selected={endDate}
                  onChange={(data)=>onChangeHandler(data,'endDate')}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="form-control"
                />
              </Form.Group>
            </Form.Row>

            <Form.Group controlId="pdf">
              <Form.Label>Upload file: </Form.Label>
              <label  className={hovered?"active pdf-modal-dragbox":"pdf-modal-dragbox"}
               onDragOver={toggleHover}
               onDrop={handleDrop} 
               onDragLeave={handleDrop}
              htmlFor="pdf">
                
                <Form.Control type="file" title="" accept="application/pdf" className="pdf-modal-dragbox-file"  onChange={(e) => handleChange(e)} />
                {numPages==1?(<div onClick={(e)=>removePDF(e)} className="pdf-close"><img src={`${Constants.FRONT_BASE_URL}/img/modal-close-icon.svg`}/></div>):null}
                
                <Row>
                  
                  <Col>
                       
                    <Document
                      file={PDFCategory}
                      error="Please upload file having extensions.pdf only."
                      noData={<div className="pdf-drag-drop-text"><div className="pdf-drag-drop-icon"><img src={`${Constants.FRONT_BASE_URL}/img/file-plus-regular.svg`}/></div>Drag & Drop<br/> or <span>browse</span></div>}
                      onLoadSuccess={onDocumentLoadSuccess}
                     
                    >
                    {!renderPdf?(
                    <div className="loaderCoderx">
                      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </div>
                    ):null}

                          <Page
                          onLoadSuccess={removeTextLayerOffset}
                          onRenderSuccess={()=>{setRenderPdf(true)}}
                          pageNumber={numPages} width="500" height="263px"/>
                      
                    
                       
                    </Document>
                   
                    
                  </Col>
                </Row>
              </label>
              
                
              
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" title={numPages==1?'':(formButtonTitle == 'Add'?'Add PDF':'Pdf Lording')}  disabled={(numPages==1 && prop.isLoading == false)?false:true}  onClick={()=>{prop.onCategoryPDFSubmit(formData)}}>
            {formButtonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PDFCategoryModal;
