import cutright from '../../assets/img/cutright.png';
import cutup from '../../assets/img/cutup.png';
import ImageTag from '../ImageTag';
import Modal from 'react-bootstrap/Modal';
import { jsPDF } from "jspdf";
import { useEffect, useState } from "react";
import html2canvas from "html2canvas";

export default function PrintModal(props){
    const [gridItemArr, setGridItemArr] = useState([]);
    const doc = new jsPDF({
        orientation: "p",
        unit: "in",
        format: [8.5, 11]
    });
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();
    let itemCheckLastElement = 0;
    const posObj = {0:{x:1.5625,y:0}, 1:{x:5.15625,y:0}, 2:{x:1.5625,y:3.4583}, 3:{x:5.15625,y:3.4583}, 4:{x: 1.5625,y: 6.9166}, 5:{x:5.15625,y:6.9166} };
    const widthHeightObj = {width: 3.43, height: 3.40};
    useEffect(()=>{
        let gridItemsSelectedArr = JSON.parse(JSON.stringify(props.girdItemsSelected));
        let lengthOfArr = gridItemsSelectedArr.length;
        if(lengthOfArr >6){
            breakGridItemsSelected();
        }else{
            const gridItemArrTemp=[];
            gridItemArrTemp[0]=gridItemsSelectedArr;
            setGridItemArr(gridItemArrTemp);
        }
        if(props.show){
            printThePDFWithPages();
        }
    },[props.girdItemsSelected, props.show]); 

    const breakGridItemsSelected = () =>{
        let gridItemsSelectedArr = JSON.parse(JSON.stringify(props.girdItemsSelected));
        let chunk = [];
        let tempGridItemArr = [];
        while (gridItemsSelectedArr.length > 0) {
            chunk = gridItemsSelectedArr.splice(0,6);
            if(chunk.length < 6){
                let lengthOfChunk = chunk.length;
                let numOfIterations = 6 - lengthOfChunk;
                for(let i=0;i<numOfIterations;i++){
                    chunk.push({filename:"", image:"", checked:false})
                }
            }
            tempGridItemArr.push(chunk);
        }
        setGridItemArr(tempGridItemArr);
    }

    const printThePDFWithPages = () =>{
        let totalPages = gridItemArr.length -1;
        props.setIsLoading(true);
        try{
            if(props.show){
                if(itemCheckLastElement > 0){
                    doc.addPage([ width, height ], 'p');
                }
                const priceModalScrollId = document.getElementById(`price-modal-scroll-id-${itemCheckLastElement}`);
                if(priceModalScrollId){
                    let image = new Image();
                    const gridItemTempArr = gridItemArr[itemCheckLastElement];
                    html2canvas(priceModalScrollId).then(function(canvas) {
                        const imgData = canvas.toDataURL("image/png");       
                        // doc.addImage(imgData, "JPEG", 0, 0); 
                        gridItemTempArr.forEach((element, index) => {
                            if(element.image != ''){
                                doc.addImage(element.image, "JPEG", posObj[index].x, posObj[index].y, widthHeightObj.width, widthHeightObj.height);    
                            }
                        });
                        doc.setLineWidth(.0104);
                        doc.setLineDash([.0208, .0208], 0);
                        doc.line(1.56, 0, 1.56, 10.4); 
                        // doc.setLineWidth(.0208);
                        doc.line(5.1, 0, 5.1, 10.4);
                        // doc.setLineWidth(.0208);
                        doc.line(1.56, 3.5, 8.45, 3.5); 
                        // doc.setLineWidth(.0208);
                        doc.line(1.56, 7, 8.45, 7);
                        doc.line(1.56, 10.4, 8.45, 10.4);
                        doc.addImage(cutright, "PNG", 1, 3.4);
                        doc.addImage(cutright, "PNG", 1, 6.9);
                        doc.addImage(cutright, "PNG", 1, 10.27);
                        doc.addImage(cutup, "PNG", 1.45, 10.45);
                        doc.addImage(cutup, "PNG", 4.97, 10.45);
                        doc.setFontSize(14);
                        doc.setTextColor(0,0,0,.6);
                        doc.text(0.0948, 1.5, 'Cut each price');
                        doc.text(0.0948, 1.8, 'tag at the');
                        doc.text(0.0948, 2.11, 'dotted line(s).');
                        doc.text(0.0948, 5, 'Cut each price');
                        doc.text(0.0948, 5.3, 'tag at the');
                        doc.text(0.0948, 5.6, 'dotted line(s).');
                        
                        if(itemCheckLastElement === totalPages){
                            itemCheckLastElement = 0;
                            doc.save("download.pdf");
                            props.setPrintPdf(false);
                            props.setIsLoading(false);
                        }else{
                            itemCheckLastElement = itemCheckLastElement+1;
                            printThePDFWithPages();
                        }
                    });
                }
            }
        }catch(err){
            props.setPrintPdf(false);
            props.setIsLoading(false);
            console.log("Error printing the PDF", err);
        }
    }

    return(
        <div className='price-tag-modal print-modal'>              
        <div className='modal-dialog'>
            {
                gridItemArr.map((element,index)=>{
                    return(
                        <div id={`price-modal-scroll-id-${index}`} className={props.show?"row price-modal-scroll zero-height":"row price-modal-scroll zero-height"}>
                            <div className="col-md-3 position-relative">
                            <div className="left-title-of-pdf">Cut each price tag at the dotted line(s).</div>
                            <div className='modal-left-icon'>
                                <ImageTag src={cutright} /> 
                            </div>
                            <div className="left-title-of-pdf">Cut each price tag at the dotted line(s).</div>
                            <div className='modal-left-icon modal-left-icon2'>
                                <ImageTag src={cutright} /> 
                            </div>
                            <div className="left-title-of-pdf"></div>
                            <div className='modal-left-icon modal-left-icon3'>
                                <ImageTag src={cutright } /> 
                            </div>
                            </div>
                            <div className="col-md-9 ">
                            <div className="row">
                            {element.map((elemData, index) => {
                                return(
                                <div className="col-md-6 price-preview-border p-0">
                                    <div className="price-preview-right-side-cover">
                                    {/* {elemData.image != '' &&
                                    <>                      
                                        <ImageTag src={elemData.image}/> 
                                    </>
                                    } */}
                                    </div>
                                </div>
                                )
                            })}
                            </div>
                            </div>
                            <div className='modal-bottom-icon '>
                            <ImageTag src={cutup} /> 
                            </div>
                            <div className='modal-bottom-icon modal-left-icon3'>
                            <ImageTag src={cutup} /> 
                            </div>
                        </div>
                    )
                }
                )                   
            }
        </div>
        </div>
    ); 
}