import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import store from './redux_modules/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Get the current URL
 const currentURL = window.location.href;

 // Save it to local storage
 localStorage.setItem('currentURL', currentURL);
    
root.render(
    <Provider store={store}>
       <App />
    </Provider>
);

