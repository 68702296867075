import React from 'react';

function ImageTag(props) {
    return (
        <>
          <img  {...props}/>  
        </>
    );
}

export default ImageTag;