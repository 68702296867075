import { useState } from "react";
import { useHttpClient } from "../../../hooks/HttpHook";
import EndPoint from "../../../api/Endpoint";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const VersionHistoryModel = () => {
    const [versionList, setVersionList] = useState([]);
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [idOfVersion, setIdOfVersion] = useState('');
    const [osValue, setOSValue] = useState('all');
    const { sendRequest } = useHttpClient();
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [dataToEdit, setDataToEdit] = useState({
        show: false,
        isEdit: false,
        data: {}
    });
    const [showLoadMoreButton, setShowLoadMoreButton] = useState(true);
    const fetchData = async (osV, page) => {
        const body={
            OS: osV,
            noOfrecords: 10,
            page: page
        }
        try {
            setIsLoading(true);
            const responseData = await sendRequest(
                EndPoint.LISTING_OF_ALL_FRONT_API,
                "POST",
                body
            );
            setIsLoading(false);
            if(responseData.records.length == responseData.count){
                setShowLoadMoreButton(false);
            }else{
                setShowLoadMoreButton(true);
            }
            // console.log("##### responseData.records #####", responseData.records);
            if(responseData.records.length > 0){
                setVersionList(responseData.records);
            }else if(responseData.records.length == 0){
                setVersionList(responseData.records);
            }
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    };

    
    const openAddVersionModal = (e, element) => {
        if(element){
          setDataToEdit({
            show: true,
            isEdit: true,
            data: element
          });
        }else{
            setDataToEdit({
                show: true,
                isEdit: false,
                data: {}
            });
        }
    };
    const closeAddVersionModal = () => {
        setDataToEdit({
            show: false,
            isEdit: false,
            data: {}
        });
    };
    const openDeleteVersionModal = (event, id) => {
        setIdOfVersion(id);
        setShowDelete(true);
    };
    const closeDeleteVersionModal = () => {
        setIdOfVersion('');
        setShowDelete(false);
    };
    const handleVersionHistoryDelete = async() => {
        const body={
            os: osValue,
            noOfrecords: 10,
            page: pageNumber
        }
        try {
            setIsLoading(true);
            const responseData = await sendRequest(
              EndPoint.DELETE_OF_VERSION_FRONT_API+`${idOfVersion}`,
              "DELETE",
              body
            );
            setIsLoading(false);
            closeDeleteVersionModal();
            if(responseData.records.length == responseData.count){
                setShowLoadMoreButton(false);
            }else{
                setShowLoadMoreButton(true);
            }
            if(responseData.records.length > 0){
                toast.success(`${responseData.message}`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
              setVersionList(responseData.records);
            }else if(responseData.records.length == 0){
                setVersionList([]);
            }
          } catch (err) {
            setIsLoading(false);
            toast.error('Version delete unsuccessful', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            closeDeleteVersionModal();
            console.log(err);
          }

    }
    const handleOSChange = (event) => {
        setOSValue(event.target.value);
        setPageNumber(1);
        fetchData(event.target.value, 1);
    }

    const addEditVersion = async (formDataArg, fromEdit) => {
        let formData = JSON.parse(JSON.stringify(formDataArg));
        if(fromEdit){
            formData.OS = formData.os;
            formData.id = formData._id;
            formData.force = formData.isForcefully;
            delete formData.os;
            delete formData.isForcefully;
            delete formData._id;
        } else{
            formData.OS = formData.os;
            formData.force = formData.isForcefully ? formData.isForcefully: false;
            delete formData.os;
            delete formData.isForcefully;
        }
        const body = formData;
        const endPointAPI = fromEdit ? EndPoint.EDIT_VERSION_FRONT_API : EndPoint.ADD_VERSION_FRONT_API;
        try{
            setIsLoading(true);
            const responseData = await sendRequest(
              endPointAPI,
              "POST",
              body
            );
            setIsLoading(false);
            closeAddVersionModal();
            let versionListTemp = JSON.parse(JSON.stringify(versionList)); 
            if(responseData.data && !fromEdit){
                versionListTemp.push(responseData.data);
                setVersionList(versionListTemp);
            }
            if(fromEdit && !responseData.error){
                formData._id = formData.id;
                formData.isForcefully = formData.force;
                formData.os = formData.OS;
                delete formData.id;
                delete formData.force;
                delete formData.OS;
                versionListTemp = versionListTemp.map((element)=>{
                    if(element._id == formData._id){
                        formData.updatedAt = Date.now();
                        formData.createdAt = element.createdAt;
                        return formData; 
                    }else{
                        return element;
                    }
                })
                setVersionList(versionListTemp);
            }

        }catch (err) {
            setIsLoading(false);
            toast.error(`${err}`, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            if(fromEdit){
                closeAddVersionModal();
            }
            console.log(err);
        }
        
    }
    const handleOnChangeForce = async(event, element) => {
        let dynamicElement = JSON.parse(JSON.stringify(element));
        dynamicElement.isForcefully = !dynamicElement.isForcefully;
        addEditVersion(dynamicElement, true);
    }
    const handleLoadMore = () =>{
        const pageCount = pageNumber;
        setPageNumber(pageCount+1);
        fetchData(osValue, pageCount+1);
    }
    return{
        show,
        versionList,
        showDelete,
        idOfVersion,
        osValue,
        isLoading,
        isEdit,
        dataToEdit,
        showLoadMoreButton,
        fetchData,
        openAddVersionModal,
        closeAddVersionModal,
        openDeleteVersionModal,
        closeDeleteVersionModal,
        handleVersionHistoryDelete,
        handleOSChange,
        addEditVersion,
        handleOnChangeForce,
        handleLoadMore
    }
}

export default VersionHistoryModel;