import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const DeleteUserModal = (prop) => {
  
  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
           <p>Are you sure you want to delete this user with email <b>{prop.selectedEmail}</b>? <br /> Once it gets deleted, app user will not be able to access frontend nor user can recover or reregister with this email.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onMouseDown={prop.handleClose}>CANCEL</Button>
          <Button variant="primary" onMouseDown={(e)=>prop.handleUserDelete(e, prop.selectedEmail)}>CONFIRM</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default DeleteUserModal;
