import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import axios from "axios";
import EndPoint from "../../api/Endpoint";

const CategoryModal = (prop) => {
  const [formData, setFormData] = useState(prop.data);
  const formTitle =
    prop.data._id != undefined ? "Edit Merch Grid" : "Create New Merch Grid";
  const formButtonTitle = prop.data._id != undefined ? "Edit" : "Add";
  useEffect(() => {
     if(prop.showModal == true && prop.data._id != undefined){
       setFormData(prop.data);
     }
     else if(prop.showModal == true && prop.data.title === ''){
        setFormData({});
     }
     else if(prop.showModal == true){
   
          setFormData({});
     }
  }, [prop]);
  const onChangeHandler = (event) => {
    if(event.target.value.length <= 100){
      setFormData({ ...formData, [event.target.name]: event.target.value });
    }
    else{
      alert('Merch Grid name cannot be more than 100 characters long.');
    }
  };

  const responseObj = prop.responseObj;


  return (
    <>
      <Modal show={prop.showModal} onHide={prop.handleClose} aria-labelledby="contained-modal-title-vcenter"
      centered>
        <Modal.Header closeButton>
          <Modal.Title>{formTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {responseObj.show ? (
            <div
              className={
                responseObj.type == "success"
                  ? "alert alert-success"
                  : "alert alert-danger"
              }
              role="alert"
            >
              {responseObj.message}
            </div>
          ) : (
            ""
          )}
          <Form>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="title"
                name="title"
                maxlength="100"
                value={formData.title}
                onChange={onChangeHandler}
                placeholder="Enter  Name"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button  disabled={prop.isLoading?true:false}  variant="primary" onClick={() => prop.onSubmit(formData)}>
            {formButtonTitle}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CategoryModal;
