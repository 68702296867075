import React from "react";
import { Link } from 'react-router-dom';
import ImageTag from "../../components/ImageTag";
import PriceOneIcon from "../../assets/img/price-one.png"
import PricetwoIcon from "../../assets/img/price-two.png"
import Constants from "../../api/Constants";

const MainIndex = () => {

    return (
        <>
            <section className="price-of-new-inner">
                <div className="">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="price-main-outer d-flex justify-content-center">
                                <Link className="price-main-outer-link-box" to={`${Constants.FRONT_BASE_PATH}/category`}>
                                    <div className="price-select-one">
                                        <div>
                                            <ImageTag src={PriceOneIcon} />
                                        </div>
                                        <div className="price-title">Merch Grids</div>
                                    </div>
                                </Link>
                                {/* <Link to={`${Constants.FRONT_BASE_PATH}/price-tag`}>

                                    <div className="price-select">
                                        <div className="image-outer-cover">
                                            <ImageTag src={PricetwoIcon} />
                                        </div>
                                        <div className="price-title">Price Tags</div>
                                    </div>
                                </Link> */}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    );
};
export default MainIndex;