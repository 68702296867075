import React from "react";
import { Button, Form, FormCheck } from "react-bootstrap";
import { Link } from "react-router-dom";
import Constants from "../../../api/Constants";

const Category = (props) => {
  const { id, title, status, _id } = props.data;
  const { provider, index, getItemStyle, snapshot } = props;

  return (

    <tr
      {...provider.draggableProps}
      {...provider.dragHandleProps}
      style={getItemStyle(snapshot.isDragging, provider.draggableProps.style)}
      ref={provider.innerRef}
      key={_id}
    >
      <td style={{ width: "90%" }}>
        <Link to={`${Constants.FRONT_BASE_PATH}/category/${id}`}>{title}</Link>
      </td>
      <td style={{ width: "100px" }}>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            checked={status == "active" ? true : false}
            className="custom-control-input"
            id={`category-custom-switch_` + id}
            onChange={() => {
              delete props.data["orderId"];
              props.handleStatusChange(props.data);
            }}
          />

          <label
            className="custom-control-label"
            htmlFor={`category-custom-switch_` + id}
          >
            <div className="custom-switch-text"></div>
          </label>
        </div>
      </td>
      <td style={{ width: "100px" }}>
        <Button
          onClick={() => {
            props.handleOpen(props.data);
          }}
          className="btn btn-secondary abc"
        >
          EDIT
        </Button>

      </td>
      <td style={{ width: "100px" }}>
        <Button
          onClick={() => {
            props.handlePdfOpen(props.data);
          }}
          className="btn btn-primary"
        >
          ADD
        </Button>
      </td>
    </tr>
  );
};
export default Category;
